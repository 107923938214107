<template>
  <div class="logo-wrapper">
    <img class="logo" v-if="currentCompany.logo != null" :src="'/images/'+currentCompany.logo"  v-bind:alt="currentCompany.name" >
    <!-- <img class="logo" src="/images/CHL_Logo.png"> -->
    <div class="tagline">{{ currentCompany.name }} {{ $t("logo.tagLine") }}</div>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  data () {
    return {
      errorMessage: '',
      currentCompany: { logo: 'CHL_Logo.png', name: 'CHL', short: 'CHL' }
    }
  },
  mounted () {
    // TODO: not working seems it needs auth
    this.loadCompanyValues()
  },
  methods: {
    loadCompanyValues () {
      // this.currentCompany = { logo: 'CHL_Logo.png', name: 'Clever Home Labs' }
      this.$store.dispatch('getCompanyData')
        .then(() => {
          /* eslint-disable no-console */
          console.log('Logo')
          /* eslint-disable no-console */
          console.log(this.$store)
          this.currentCompany = this.$store.getters.company
        })
        .catch(() => {
          /* eslint-disable no-console */
          console.log('could not get company data')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.logo-wrapper {
  text-align: center;
  margin-bottom: 40px;

  .tagline {
    line-height: 180%;
    font-weight: 700;
    color: #666;
  }

  .logo {
    max-width: 218px;
    margin: 0 auto;
  }
}
</style>
