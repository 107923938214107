<template>
  <transition name="modal">
    <div class="frm-modal-mask">
      <div class="frm-modal-wrapper" ref="modalwrapper" @keyup.esc="close()" tabindex="-1">
        <div class="frm-modal-container">
          <div class="frm-modal-header">
            {{ $t('passwordForgottenModal.title') }}
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- <form @submit.prevent="passwordForgotten" ref="passwordForgottenForm"> -->
          <div class="frm-modal-body">
            <div class="text-block">
              {{ $t('passwordForgottenModal.text') }}
            </div>
            <div class="form">
              <div class="form-group">
                <label for="userNameInput">{{ $t('passwordForgottenModal.userNameInput') }}</label>
                <div class="input-group">
                  <input type="text" class="form-control" :class="{ 'is-invalid': $v.userName.$error }" id="userNameInput" ref="userNameInput" v-model.trim="userName" :placeholder="$t('passwordForgottenModal.userNamePlaceholder')" maxlength="128">
                </div>
                <div class="invalid-feedback" v-if="$v.userName.$dirty">
                  <div v-if="$v.userName.$error">{{ $t('passwordForgottenModal.userNameRequired') }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="frm-modal-footer">
            <div class="form-group">
              <button type="button" class="btn btn-secondary" @click="close()">{{ $t('passwordForgottenModal.cancelButton') }}</button>
              <button type="button" class="btn btn-primary right-button"  @click="passwordForgotten" ref="submitRequest"> {{ $t('passwordForgottenModal.submitButton') }} </button>
            </div>
            <error-row @clearError="clearError" :errorMessage="errorMessage" />
          </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'
import clientService from '@/services/client'
import ErrorRow from '@/components/ErrorRow.vue'

export default {
  name: 'PasswordForgottenModal',
  components: {
    'error-row': ErrorRow
  },
  props: {
    suggestedUserName: {
      type: String
    },
    company: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      userName: '',
      errorMessage: ''
    }
  },
  validations: {
    userName: {
      required,
      maxLength: maxLength(128)
    }
  },
  mounted () {
    this.userName = this.suggestedUserName
    this.errorMessage = ''
    this.$refs.userNameInput.focus()
  },
  methods: {
    passwordForgotten () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      const payload = { username: this.userName, company: this.company.name }
      clientService.passwordForgotten(payload).then((answer) => {
        this.close()
      }).catch(error => {
        this.errorMessage = this.localizedBackendMessage(error.message)
      })
    },
    clearError () {
      this.errorMessage = ''
    },
    close () {
      this.$v.$reset()
      this.errorMessage = ''
      this.$emit('close')
    },
    localizedBackendMessage (errorMessage) {
      return this.$t('backend.' + errorMessage)
    }
  }
}
</script>

<style lang="scss" scoped>
  .frm-modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .frm-modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .frm-modal-container {
    max-width: 600px;
    margin: 0px auto;
    background-color: White;
    border: thin solid #0062cc;
    border-radius: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-variant: normal;
    line-height: 130%;
    color: Black;
  }

  .frm-modal-header {
    display: flex;
    display: -ms-flexbox;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-box-pack: justify;
    align-items: flex-start;
    padding: 0.5rem 0.5rem;
    color: Black;
    background-color: Darkgrey;
    font-weight: 700;
  }

  .frm-modal-body {
    padding: 0.5rem;
    min-height: 6rem;
  }

  .frm-modal-footer {
    justify-content: start;
    border-top: none;
    padding: 0.5rem;
  }

  .right-button {
    margin-left: 1rem;
  }

  .text-block {
    margin-bottom: 20px;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .frm-modal-container,
  .modal-leave-active .frm-modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>
