<template>
  <div class="password-control">
    <!-- https://github.com/skegel13/vue-password/blob/master/src/components/password-control.vue -->
    <div class="password-control-container">
      <slot
        name="password-input"
        :type="type"
        :updatePassword="updatePassword"
        :value="value"
      >
        <input
          ref="input"
          :class="[
            classes,
            'password-control-input',
            { 'password-control-input-has-toggle': !disableToggle }
          ]"
          :title="strengthMessage"
          :value="value"
          v-bind="$attrs"
          v-on="listeners"
        />
      </slot>
      <slot
        v-if="!disableToggle"
        name="password-toggle"
        :toggle="togglePassword"
      >
        <button
          class="password-control-toggle"
          :title="toggleMessage"
          type="button"
          @click="togglePassword"
        >
          <slot name="password-hide" v-if="type === 'text'">
            <svg
              class="password-control-toggle-icon password-control-toggle-icon--hide"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M12.81 4.36l-1.77 1.78a4 4 0 0 0-4.9 4.9l-2.76 2.75C2.06 12.79.96 11.49.2 10a11 11 0 0 1 12.6-5.64zm3.8 1.85c1.33 1 2.43 2.3 3.2 3.79a11 11 0 0 1-12.62 5.64l1.77-1.78a4 4 0 0 0 4.9-4.9l2.76-2.75zm-.25-3.99l1.42 1.42L3.64 17.78l-1.42-1.42L16.36 2.22z"
              />
            </svg>
          </slot>
          <slot name="password-show" v-else>
            <svg
              class="password-control-toggle-icon password-control-toggle-icon--show"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M.2 10a11 11 0 0 1 19.6 0A11 11 0 0 1 .2 10zm9.8 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
              />
            </svg>
          </slot>
        </button>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PasswordControl',
  inheritAttrs: false,
  data () {
    return {
      type: this.$attrs.type
    }
  },
  props: {
    classes: {
      type: [Object, Array, String]
    },
    disableToggle: Boolean,
    value: String
  },
  computed: {
    inputElement () {
      if (this.$refs.input) {
        return this.$refs.input
      }
      if (this.$el) {
        return this.$el.querySelector('.password-control-container input')
      }
      return null
    },
    listeners () {
      return Object.assign(
        {},
        this.$listeners,
        { input: this.updatePassword }
      )
    },
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    toggleMessage () {
      return this.type === 'password' ? 'Show Password' : 'Hide Password'
    }
  },
  mounted () {
    if (!this.$attrs.type) {
      this.togglePassword(false)
    }
  },
  methods: {
    togglePassword (focus = true) {
      this.type = this.type === 'password' ? 'text' : 'password'
      this.inputElement.setAttribute('type', this.type)
      if (focus) {
        this.inputElement.focus()
      }
    },
    updatePassword (event) {
      this.model = event.target.value
    }
  }
}

</script>

<style lang="scss" scoped>

.password-control-container {
  align-items: center;
  display: flex;
  text-align: center;
  position: relative;
}

.password-control-input {
  border: 1px solid #cbd5e0;
  border-radius: 0.25rem;
  display: block;
  padding: 0.5rem 0.75rem;
  width: 100%;
}

.password-control-input-has-toggle {
  padding-right: 2.5rem;
}

.password-control-toggle {
  align-items: center;
  background: none;
  border: none;
  bottom: 0;
  color: #718096;
  display: flex;
  margin-left: 1rem;
  margin-right: 1rem;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
}

.password-control-toggle:hover {
  color: #4a5568;
  transition: color 0.3s ease-in-out;
}

.password-control-toggle-icon {
  fill: currentColor;
  pointer-events: none;
  width: 1rem;
}

.is-invalid {
  border-color: #dc3545;
  padding-right: 2.5rem !important;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

</style>
