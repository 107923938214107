<template>
  <div class="container public">
    <page-header />
    <div class="content-container">
      <div class="main-title">
       <h2 class="section-title">{{ $t('homePage.title') }}</h2>
      </div>
      <div class="main-section">
        <div class="btn-toolbar" v-if="!currentFacility">
          <button type="button" class="btn btn-primary mr-3" @click="loadDailyTotals()">
            <font-awesome-icon icon="calendar-day" /> &nbsp; {{ $t('homePage.dailyTotals') }}
          </button>
          <button type="button" class="btn btn-primary mr-3" @click="loadLatestCDCDataValues()">
            <font-awesome-icon icon="history" /> &nbsp; {{ $t('homePage.updateLatestData') }}
          </button>
          <div id="reloadContainer">
            <div id="reloadText"><span id="smallRefreshText">60s</span></div>
            <div id="infoReload">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgba(255, 255, 255, 0); display: block;" width="32px" height="32px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
              <g>
                <path d="M50 18A32 32 0 1 0 72.62741699796953 27.372583002030485" fill="none" stroke="#6695c2" stroke-width="8"></path>
                <path d="M49 0L49 36L67 18L49 0" fill="#6695c2"></path>
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="50s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
              </g>
              </svg>
            </div>
          </div>
          <span v-show="isLoading"><button class="btn btn-primary"><span class="spinner-border spinner-border-sm"></span></button><span> {{ $t('homePage.isLoading') }} </span></span>
        </div>
        <div class="btn-toolbar" v-else>
          <button v-if="!sensorMode" type="button" class="btn btn-info mr-3" @click="backToOverview()">
            <font-awesome-icon icon="arrow-alt-circle-left" /> &nbsp; {{ $t('homePage.backToOverview') }}
          </button>
          <button v-else type="button" class="btn btn-info mr-3" @click="backToFacility()">
            <font-awesome-icon icon="arrow-alt-circle-left" /> &nbsp; {{ $t('homePage.backToFacility') }}
          </button>
          <button type="button" class="btn btn-primary mr-3" @click="loadLatestCDCDataValues()">
            <font-awesome-icon icon="history" /> &nbsp; {{ $t('homePage.updateLatestData') }}
          </button>
          <div id="reloadContainer">
            <div id="reloadText"><span id="smallRefreshText">60s</span></div>
            <div id="infoReload">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgba(255, 255, 255, 0); display: block;" width="32px" height="32px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
              <g>
                <path d="M50 18A32 32 0 1 0 72.62741699796953 27.372583002030485" fill="none" stroke="#6695c2" stroke-width="8"></path>
                <path d="M49 0L49 36L67 18L49 0" fill="#6695c2"></path>
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="50s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
              </g>
              </svg>
            </div>
          </div>
          <span v-show="isLoading"><button class="btn btn-primary"><span class="spinner-border spinner-border-sm"></span></button><span> {{ $t('homePage.isLoading') }} </span></span>
        </div>
        <error-row @clearError="clearError" :errorMessage="errorMessage" />
      </div>
      <div class="btn-toolbar" v-if="!currentFacility">
        <div  v-if="showDailyTotals" style="padding:15px 0px">
            <div class="btn-toolbar">
               <div>
                <vue-ctk-date-time-picker v-model="dayOfInterest" noLabel="true" enable-button-validate="true" :button-now-translation="$t('homePage.now')" format="YYYY-MM-DD" formatted="ddd D MMM YYYY" only-date />
               </div>&nbsp;
               <button type="button" class="btn btn-info action_btn" v-on:click="downloadTSVData">
                     Download
               </button>&nbsp;
              <button type="button" class="btn btn-info mr-3" @click="hideDailyTotals()">
                <font-awesome-icon icon="window-close" /> &nbsp; {{ $t('homePage.close') }}
              </button>
            </div>
          <table class="meter-readings">
          <tr><th>{{ $t('homePage.type') }}</th><th>{{ $t('homePage.sensorName') }}</th><th>{{ $t('homePage.meterInfo') }}</th><th style="text-align: right;">{{ $t('homePage.value') }}</th><th>{{ $t('homePage.unit') }}</th><th>{{ $t('homePage.obis') }}</th><th>{{ $t('homePage.date') }}</th><th>{{ $t('homePage.facilityName') }}</th></tr>
          <tr v-for="total in dailyTotals.dailytotals" v-bind:key="total.id">
            <td><img :src="sensorTypeIcon(total.type)" width="20" :title="total.typestring"></td><td>{{ total.sensorName }}</td><td>{{ total.meterinfo }}</td><td style="text-align: right;">{{ valueWithFactor(total.value, total.factor) }}</td><td>{{ total.unit }}</td><td>{{ total.obis }}</td><td>{{ formatDate(total.lastupdate) }}</td><td>{{ total.facilityName }}</td>
          </tr>
          </table>
        </div>
      </div>
      <div class="facility-wrapper" v-if="!currentFacility">
        <div v-for="facility in facilitiesLocal" v-bind:key="facility.id">
          <div v-if="!facilityIsDisabled(facility) || showHiddenFacilities">
            <div class="facility-header pointer" :style="facilityStyleHasAlerts(facility)" @click="showFacility(facility)">
              <div class="facility-title"><img src="/images/facility_circle.png" width="20"><span class="facility-name">{{ facility.name }}</span> <div class="alert-reason-wrapper"><div class='alert-reason-icon'><img :src="facilityAlertReasons(facility).icon" :title="facilityAlertReasons(facility).text" width="20" ></div><div class="alert-reason-number" :class="{ 'facility-reason-number-inactive': facilityHideReasonNumber(facility) }">{{ facilityAlertReasons(facility).reasons.length }}</div></div></div>
              <div class="facility-time"> {{ fromNowFacilityDisplay(facility) }} </div>
            </div>
          </div>
        </div>
        <div v-if="facilityDisabledCount()">
          <span class="facility-name">{{ facilityDisabledCount() }}</span> {{ $t('homePage.disabledFacilities') }}<button class="btn btn-sm" @click="toggleShowHiddenFacilities">{{ $t('homePage.showHideHidden') }}</button>
        </div>
      </div>
      <div class="facility-wrapper" v-if="currentFacility">
        <!--<div class="facility-header" :class="{ 'facility-header-suspect': facilityIsOutdated(currentFacility),  'facility-header-sub-suspect': facilityHasAlerts(currentFacility), 'facility-header-inactive': facilityIsDisabled(currentFacility) }">-->
         <div style="position: relative;">
          <div class="facility-header" :style="facilityStyleHasAlerts(currentFacility)">
              <div class="facility-title"><img src="/images/facility_circle.png" width="20"><span class="facility-name">{{ currentFacility.name }}</span>
              <div class="location-wrapper" v-if="hasCoordinates(currentFacility)"><button class="btn btn-sm" @click="openInAppleMap(currentFacility)"><img class="location-image" src="/images/Location_black.png"></button></div><div class="alert-reason-wrapper"><div class='alert-reason-icon'><img :src="facilityAlertReasons(currentFacility).icon" :title="facilityAlertReasons(currentFacility).text" width="20" ></div><div class="alert-reason-number" :class="{ 'facility-reason-number-inactive': facilityHideReasonNumber(currentFacility) }">{{ facilityAlertReasons(currentFacility).reasons.length }}</div></div></div>
              <div class="facility-time"> {{ fromNowFacilityDisplay(currentFacility) }}</div>
          </div>
          <div class="facility-details" v-if="showFacilityDetail">
            <div class="facility-detail-wrapper">
              <div><img class="facility-image" :src="facilityImagePath(currentFacility)"></div>
              <div v-if="hasCoordinates(currentFacility)">
                <!--<p><button class="btn btn-outline-info btn-sm" @click="showMapSnapshot(currentFacility)">{{ $t('facilitiesPage.showMapSnapshot') }}</button><p>-->
              </div>
            </div>
          </div>
         </div>

        <div class = "device-wrapper" v-if="!sensorMode">
          <div v-for="device in visibleDevices(currentFacility)" v-bind:key="device.id">
            <div class="device-item" :style="deviceStyleHasAlerts(device)">
              <div class="device-headline"><img src="/images/cdc_circle.png" width="20"><span class="device-name">{{ deviceName(device) }}</span> <div class="alert-reason-wrapper"><div class='alert-reason-icon'><img :src="deviceAlertReason(device).icon" :title="deviceAlertReason(device).text" width="20" ></div><div class="alert-reason-number" :class="{ 'device-reason-number-inactive': deviceHideReasonNumber(device) }">{{ deviceAlertReason(device).reasons.length }}</div></div></div>
              <div class="device-time">{{ fromNowDeviceDisplay(device) }}</div>
            </div>
            <div class = "sensor-wrapper">
              <div v-for="sensor in visibleSensors(device)" v-bind:key="sensor.id">
                <div v-if="dataPackageForSensor(sensor)" class="sensor-item-headline pointer" :style="sensorStyleAlertStatus(sensor)" :class="{ 'sensor-item-inactive': !sensor.enabled }" @click="showSensor(sensor)">
                  <div><img :src="sensorIcon(sensor)" width="20"><span class="sensor-name">{{ sensor.name }}</span><img :src="sensorAlertIcon(sensor)" :title="sensorAlertReasonText(sensor)" width="20" style="float:right; margin-left: 0.5em;"></div>
                </div>
                <div v-else class="sensor-item-headline" :style="sensorStyleAlertStatus(sensor)" :class="{ 'sensor-item-inactive': !sensor.enabled }">
                  <div><img :src="sensorIcon(sensor)" width="20"><span class="sensor-name">{{ sensor.name }}</span><img :src="sensorAlertIcon(sensor)" :title="sensorAlertReasonText(sensor)" width="20" style="float:right; margin-left: 0.5em;"></div>
                </div>
                <div class="sensor-item-content">
                  <div v-if="false && sensorHasTotal(sensor)"><!-- FIXME!!! -->
                    <div class="sensor-item-value"> {{ $t('homePage.headlineSensorTotalSection') }} </div>
                    <div class="sensor-item-system"> {{ totalSensorSystemDisplay(sensor) }} </div>
                    <div class="sensor-item-value"> {{ totalSensorValueDisplay(sensor) }} <span class="sensor-item-unit"> {{ totalSensorUnitDisplay(sensor) }} ( {{ totalSensorAccuracyDisplay(sensor) }} % {{ $t('homePage.missingRatioText') }})</span></div>
                    <div class="sensor-item-time"> {{ totalFromNowSensorDisplay(sensor) }} </div>
                  </div>
                  <div v-else>
                    <template v-if="dataPackageForSensor(sensor)">
                      <div class="sensor-item-system"> {{ sensorSystemDisplay(sensor) }} </div>
                      <div class="sensor-item-value"> {{ sensorValueDisplay(sensor) }} <span class="sensor-item-unit"> {{ sensorUnitDisplay(sensor) }} </span></div>
                      <div class="sensor-item-time"> {{ fromNowSensorDisplay(sensor) }} </div>
                    </template>
                    <template v-else>
                      <div class="sensor-item-value"> {{ sensor.model }} </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <!-- COLLECTION ADDRESS SECTION -->
            <div v-if="hasCollections(device)" class = "collections-wrapper">
              <div class = "sensor-wrapper">
                <div v-for="collection in visibleCollections(device)" v-bind:key="collection.id">
                  <div class="sensor-item-headline pointer" :style="sensorStyleAlertStatus(collection)" :class="{ 'sensor-item-suspect': sensorIsOutdated(collection), 'sensor-item-inactive': !collection.enabled }" @click="showSensor(collection)">
                    <div><img :src="collectionIcon(collection)" width="20"><span class="sensor-name">{{ collection.name }}</span></div>
                  </div>
                  <div class="sensor-item-content">
                    <div class="sensor-item-system"> {{ sensorSystemDisplay(collection) }} </div>
                    <div class="sensor-item-value"> {{ sensorValueDisplay(collection) }} <span class="sensor-item-unit"> {{ sensorUnitDisplay(collection) }} </span></div>
                    <div class="sensor-item-time"> {{ fromNowSensorDisplay(collection) }} </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class = "device-wrapper" v-if="sensorMode">
          <div class="device-item" :style="deviceStyleHasAlerts(currentDevice)">
            <div class="device-headline"><img src="/images/cdc_circle.png" width="20"><span class="device-name">{{ deviceName(currentDevice) }}</span><div class="alert-reason-wrapper"><div class='alert-reason-icon'><img :src="deviceAlertReason(currentDevice).icon" :title="deviceAlertReason(currentDevice).text" width="20" ></div><div class="alert-reason-number" :class="{ 'device-reason-number-inactive': deviceHideReasonNumber(currentDevice) }">{{ deviceAlertReason(currentDevice).reasons.length }}</div></div></div>
            <div class="device-time">{{ fromNowDeviceDisplay(currentDevice) }}</div>
          </div>
          <div class = "device-item" :style="sensorStyleAlertStatus(currentSensor)" :class="{ 'sensor-item-inactive': !currentSensor.enabled }">
            <div class="device-headline">
              <div><img :src="sensorIcon(currentSensor)" width="20"><span class="sensor-name">{{ currentSensor.name }}</span></div>
            </div>
          </div>
          <div class = "sensor-wrapper">
            <div v-if="providesFirstGlanceData(currentSensor)" class="sensor-value-content-not-hidden graph-content">
              <div v-show="statsDataLoading && !hasSensorAggregations"><span class="spinner-border spinner-border-sm"></span> {{ $t('homePage.loadingStatsData') }}</div>
              <div v-show="chartDataLoading && !hasCdcdatavalues"><span class="spinner-border spinner-border-sm"></span> {{ $t('homePage.loadingChartData') }}</div>
              <div v-show="currentSensor.id >= 0">
                <div v-show="showChartOptions">
                  <div class="btn-toolbar">
                    <button type="button" class="btn btn-light mr-3" @click="toggleShowChartOptions()">{{ $t('homePage.hideChartOptions') }}</button>
                    <div v-show="chartViewStatus === 1">
                      <button type="button" class="btn btn-light mr-3" @click="toggleChartDuration()">1h/24h</button>
                    </div>
                    <div v-show="chartViewStatus === 2">
                      <button type="button" class="btn btn-light mr-3" @click="toggleStatsDuration()">{{ $t('homePage.WocheMonat') }}</button>
                    </div>
                    <div class="btn-toolbar">
                      <button type="button" class="btn btn-light mr-3" @click="previousChart()">&larr;</button>
                      <div>
                        <vue-ctk-date-time-picker v-model="selectedChartDateFromPicker" noLabel="true" enable-button-validate="true" :button-now-translation="$t('homePage.now')" format="YYYY-MM-DD" formatted="ddd D MMM YYYY" only-date />
                      </div>
                      <div>&nbsp;&nbsp;</div>
                      <button type="button" class="btn btn-light mr-3" @click="nextChart()">&rarr;</button>
                    </div>
                    <!--
                    <select class="form-control" id="systemPartAndTypeSelect" v-model="currentSystemPartAndType" >
                      <option v-for="protocol in cdcSensorProtocols" :value="protocol" :key="protocol">
                        {{ sensorProtocol(protocol) }}
                      </option>
                    </select>
                    -->
                    <div class="spacer">&nbsp;</div>
                    <div v-if="chartViewStatus === 1">
                      <button type="button" class="btn btn-light mr-3" @click="toggleChartView()">{{ $t('homePage.showStatsView') }}</button>
                    </div>
                    <div v-else>
                      <button type="button" class="btn btn-light mr-3" @click="toggleChartView()">{{ $t('homePage.showDataView') }}</button>
                    </div>
                  </div>
                </div>
                <div v-show="!showChartOptions">
                  <div class="btn-toolbar">
                    <button type="button" class="btn btn-light mr-3" @click="toggleShowChartOptions()">{{ $t('homePage.chartOptions') }}</button>
                    <div class="centeredText">{{ formatedChartDate() }} </div>
                    <!--<div class="spacer">&nbsp;</div>-->
                    <div v-if="chartViewStatus === 1">
                      <button type="button" class="btn btn-light mr-3" @click="toggleChartView()">{{ $t('homePage.showStatsView') }}</button>
                    </div>
                    <div v-else>
                      <button type="button" class="btn btn-light mr-3" @click="toggleChartView()">{{ $t('homePage.showDataView') }}</button>
                    </div>
                  </div>
                </div>
                <div v-show="chartViewStatus === 1 && hasCdcdatavalues">
                  <div>
                    <div v-show="chartDataLoading"><span class="spinner-border spinner-border-sm"></span> {{ $t('homePage.loadingChartData') }}</div>
                    <div v-show="!chartDataLoading">&nbsp;</div>
                  </div>
                  <div v-if="currentSensor.type === 1 || currentSensor.type === 2">
                   <column-chart :data="chartDataSets(currentSensor)" :dataset="barChartDataSet()" :library="chartOptions()" :download="true" />
                  </div>
                  <div v-if="currentSensor.type === 0 || currentSensor.type > 2">
                   <area-chart :data="chartDataSets(currentSensor)" :dataset="areaChartDataSet()" :library="chartOptions()" :download="true" ></area-chart>
                  </div>
                </div>
                <div v-show="chartViewStatus === 2 && hasSensorAggregations">
                   <column-chart :data="statsDataSets(currentSensor)"  :download="true" />
                </div>
              </div>
            </div>
            <div v-if="sensorHasTotal(currentSensor)" class="sensor-value-content">
              <div class="sensor-item-value"> {{ $t('homePage.headlineSensorTotalSection') }} </div>
              <div class="sensor-item-system"> {{ totalSensorSystemDisplay(currentSensor) }} </div>
              <div class="sensor-item-value"> {{ totalSensorValueDisplay(currentSensor) }} <span class="sensor-item-unit"> {{ totalSensorUnitDisplay(currentSensor) }} </span><span v-if="!sensorHasTotalSinceStart(currentSensor)" class="sensor-item-unit"> ({{ totalSensorAccuracyDisplay(currentSensor) }} % {{ $t('homePage.missingRatioText') }}) </span></div>
              <div class="sensor-item-time"> {{ totalFromNowSensorDisplay(currentSensor) }} </div>
            </div>
            <div class="sensor-value-content">
              <div class="sensor-item-system"> {{ sensorSystemDisplay(currentSensor) }} </div>
              <div class="sensor-item-value"> {{ sensorValueDisplay(currentSensor) }} <span class="sensor-item-unit"> {{ sensorUnitDisplay(currentSensor) }} </span></div>
              <div class="sensor-item-time"> {{ fromNowSensorDisplay(currentSensor) }} </div>
            </div>
            <div class="sensor-value-content" v-for="value in nonFirstGlanceValues(currentSensor)" v-bind:key="value">
              <div class="sensor-item-system"> {{ stringForSystemPartAndValueType(value.systempart, value.type) }} </div>
              <div class="sensor-item-value"> {{ formattedValue(value, value.type) }} <span class="sensor-item-unit"> {{ unitDisplay(value) }} </span></div>
              <div class="sensor-item-time"> &nbsp; </div>
              <!--<div class="sensor-item-time"> {{ value.systempart }} {{ value.type }} </div>-->
            </div>
          </div>
          <div class="btn-toolbar meter-reading" v-if="sensorHasTotal(currentSensor)">
            <button type="button" class="btn btn-primary mr-3" @click="manualMeterReading(currentSensor)">
              <font-awesome-icon icon="edit" /> &nbsp; {{ $t('homePage.manualMeterReading') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <page-footer />
    <edit-sensor-total-modal @modalClosed="modalClosed" :selectedSensor="currentSensor" action="add" :modalOpened="modalTimestamp" />
    <map-snapshot-modal @modalClosed="modalClosed" :selectedOrganization="selectedOrganization" action="add" :selectedFacility="showMapFacility" :modalOpened="modalTimestamp" />
  </div>
</template>

<script>
import moment from 'moment'
import objectSorter from '@/utils/object-sorter'
import PageViewMixin from '@/mixins/PageViewMixin.js'
import PageHeader from '@/components/PageHeader.vue'
import PageFooter from '@/components/PageFooter.vue'
import EditSensorTotalModal from '@/modals/EditSensorTotalModal.vue'
import { mapGetters } from 'vuex'
import MapSnapshotModal from '@/modals/MapSnapshotModal.vue'
// import organizationService from '@/services/organizations'

const AlertReason = {
  NONE: 0,
  DEVICE_DISABLED: 1,
  DEVICE_CONTACT: 2,
  STALE_DATA: 3,
  BATTERY_LOW: 4,
  USAGE_LIMIT: 5,
  SENSOR_WARNING: 6,
  SENSOR_ALERT: 7,
  SENSOR_DISABLED: 8,
  NO_SENSORDATA: 9,
  NO_SENSORDATA_DIRECT: 10,
  SENSOR_EVENT: 11,
  UNKNOWN: -1
}

const AlertState = {
  OK: 0,
  INFO: 1,
  WARNING: 2,
  CRITICAL: 3,
  UNKNOWN: -1
}

export default {
  name: 'HomePage',
  mixins: [PageViewMixin],
  data () {
    return {
      loaded: false,
      currentFacility: null,
      currentDevice: null,
      currentSensor: { id: -1, name: '' },
      sensorMode: false,
      facilityGradient: 'linear-gradient(90deg, rgba(251,199,66,1) 0%, rgba(127,232,127,1) 35%, rgba(127,232,127,1) 100%)', // variable to be accessable from the css section using v-bind,
      deviceGradient: 'linear-gradient(90deg, rgba(251,199,66,1) 0%, rgba(127,232,127,1) 35%, rgba(127,232,127,1) 100%)',
      showFacilityDetail: false,
      organizationid: 0,
      selectedOrganization: { id: -1, name: '', code: '' },
      currentDetailedFacility: null,
      showMapFacility: {},
      selectedDetailFacility: { id: -1, name: '' },
      dayOfInterest: new Date(), // moment(new Date()).subtract(24, 'hours').toDate(),
      showDailyTotals: false,
      chartDuration: 1,
      statsDuration: 7,
      chartStartDate: moment(new Date()).subtract(1, 'hours').toDate(),
      chartEndDate: new Date(),
      selectedChartDateFromPicker: new Date(),
      selectedChartDate: new Date(),
      showChartOptions: false,
      chartDataLoading: false,
      statsDataLoading: false,
      chartViewStatus: 1 // 0: no chart, 1: data, 2: stats
    }
  },
  computed: {
    ...mapGetters([
      'client',
      'organizations',
      'hasOrganizations',
      'cdcdatavalues',
      'hasCdcdatavalues',
      'facilities',
      'company',
      'dailyTotals',
      'sensorAggregations',
      'hasSensorAggregations'
    ]),
    facilitiesLocal () {
      const organization = this.hasOrganizations ? this.organizations[0] : null
      return organization != null ? organization.facilities : []
    },
    cdcDataSortedByTime () {
      return this.hasCdcdatavalues ? objectSorter.sortedArray(this.cdcdatavalues, 'asc', 'recordedtime') : []
    },
    emptyFacility () {
      return {
        id: -1,
        organizationid: this.selectedOrganization.id,
        name: '',
        description: '',
        geolatitude: null,
        geolongitude: null
      }
    }
  },
  created () {
    // this.loadLatestCDCDataValues()
    this.timer = setInterval(this.loadLatestCDCDataValues, 60 * 1000) // alle 60 Sekunden
    moment.updateLocale('de', {
      relativeTime: {
        future: 'in %s',
        past: 'vor %s',
        s: 'einigen Sekunden',
        ss: '%d Sekunden',
        m: 'einer Minute',
        mm: '%d Minuten',
        h: 'einer Stunde',
        hh: '%d Stunden',
        d: 'einem Tag',
        dd: '%d Tagen',
        w: 'einer Woche',
        ww: '%d Wochen',
        M: 'einem Monat',
        MM: '%d Monaten',
        y: 'einem Jahr',
        yy: '%d Jahren'
      }
    })
  },
  destroyed () {
    /* eslint-disable no-console */
    // console.log('destroyed')
    this.$store.dispatch('discardLatestSensorData')
    this.$store.dispatch('discardCDCDataValuesSlim')
    this.$store.dispatch('discardDailyTotals')
    this.$store.dispatch('discardSensorAggregations')
    this.chartDuration = 1
    this.statsDuration = 7
    clearInterval(this.timer)
  },
  mounted () {
    this.clearError()
    if (this.client.organization.id > 0) {
      this.loadLatestCDCDataValues()
    }

    /* eslint-disable no-console */
    //  console.log(this.$store)

    /* eslint-disable no-console */
    // console.log('company.name:' + this.company.name)
    if (this.company != null && this.company.name === 'Clever Home Labs') {
      this.showFacilityDetail = true
    }
    if (this.client.organization.id > 0) {
      /* eslint-disable no-console */
      // console.log('organizationid:' + this.client.organization.id)
      this.organizationid = this.client.organization.id
      this.selectedOrganization = { id: this.client.organization.id, name: '', code: '' }
      this.loadDetailedFacilities(true)
    }
    this.showDailyTotals = false
  },
  components: {
    'page-header': PageHeader,
    'page-footer': PageFooter,
    'edit-sensor-total-modal': EditSensorTotalModal,
    'map-snapshot-modal': MapSnapshotModal
  },
  watch: {
    client () {
      if (this.client.organization.id > 0) {
        this.loadLatestCDCDataValues()
        // also load the facility details seem on mount is to early
        if (this.company != null && this.company.name === 'Clever Home Labs') {
          this.showFacilityDetail = true
        }
        this.organizationid = this.client.organization.id
        this.selectedOrganization = { id: this.client.organization.id, name: '', code: '' }
        this.loadDetailedFacilities(true)
      }
    },
    cdcdatavalues () {
      this.chartDataLoading = false
      this.setChartDates()
      /* eslint-disable no-console */
      // console.log('cdcdatavalues changed')
    },
    facilities () {
      this.isLoading = false
      /* eslint-disable no-console */
      // console.log('facilities changed:' + this.facilities)
      // this.selectedDetailFacility = facilities
    },
    currentSensor () {
      this.selectedChartDate = new Date()
      this.chartDuration = 1
      this.chartStartDate = moment(this.selectedChartDate).subtract(this.chartDuration, 'hours').toDate()
      this.chartEndDate = moment(this.selectedChartDate).toDate()
      /* eslint-disable no-console */
      // console.log('sensor changed:' + this.sensor)
      if (this.currentSensor.id > -1) {
        this.loadWeeklyAggregations()
      }
    },
    dailyTotals () {
      /* eslint-disable no-console */
      // console.log('dailyTotals changed:' + this.dailyTotals)
    },
    sensorAggregations () {
      this.statsDataLoading = false
      /* eslint-disable no-console */
      // console.log('sensorAggregations changed:' + JSON.stringify(this.sensorAggregations))
    },
    dayOfInterest () {
      // load
      this.loadDailyTotals()
    },
    chartDuration () {
      // reload chart data
      this.setChartDates()
      this.loadDataValues()
    },
    statsDuration () {
      // reload chart data
      this.setChartDates()
      this.loadWeeklyAggregations()
    },
    selectedChartDateFromPicker () {
      if (this.selectedChartDateFromPicker === null) {
        // if you press the x on the picker it  sets the date to null
        this.selectedChartDateFromPicker = new Date()
      }
      if (this.selectedChartDateFromPicker !== this.selectedChartDate) {
        this.selectedChartDate = new Date(this.selectedChartDateFromPicker)
      }
    },
    selectedChartDate () {
      this.selectedChartDateFromPicker = this.selectedChartDate
      this.setChartDates()
      this.loadDataValues()
      this.loadWeeklyAggregations()
    }
  },
  methods: {
    formatedChartDate () {
      var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      if (this.selectedChartDate != null) {
        return this.selectedChartDate.toLocaleDateString('de-DE', options)
      }
      return ''
    },
    setChartDates () {
      if (this.chartDuration === 1) {
        // var now = new Date()
        var selected = new Date(this.selectedChartDate)
        // just set the hour window to the current time
        // selected.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), 0)
        this.chartStartDate = moment(selected).subtract(this.chartDuration, 'hours').toDate()
        this.chartEndDate = moment(selected).toDate()
      } else {
        var start = new Date(this.selectedChartDate)
        start.setHours(0, 0, 0, 0)
        this.chartStartDate = start
        var end = new Date(this.selectedChartDate)
        end.setHours(23, 59, 59, 999)
        this.chartEndDate = end
      }
      /* eslint-disable no-console */
      // console.log(' Start:' + this.chartStartDate + ' End:' + this.chartEndDate)
    },
    valueWithFactor (value, factor) {
      /* eslint-disable no-console */
      // console.log(value + ' ' + factor)
      if (value) {
        return parseFloat(value).toLocaleString('de-DE')
        // return parseFloat(value * Math.pow(10, factor)).toLocaleString('de-DE')
      }
      return 0.0
    },
    createTSV () {
      /* eslint-disable no-console */
      // console.log('createTSV')
      if (this.dailyTotals.dailytotals) {
        let csv = 'Typ\tExt. Sensor Id\tSensor Name\tMeter Info\tWert\tEinheit\tOBIS\tDatum\tExt. Facility Id\tObjekt Name\n'
        this.dailyTotals.dailytotals.forEach((total) => {
          csv += this.sensorTypeLocalized(total.type)
          csv += '\t'
          csv += total.externalsensorid
          csv += '\t'
          csv += total.sensorName
          csv += '\t'
          csv += total.meterinfo
          csv += '\t'
          csv += this.valueWithFactor(total.value, total.factor)
          csv += '\t'
          csv += total.unit
          csv += '\t'
          csv += total.obis
          csv += '\t'
          csv += this.formatDateTime(total.lastupdate)
          csv += '\t'
          csv += total.externalfacilityid
          csv += '\t'
          csv += total.facilityName
          csv += '\n'
        })
        return csv
      }
    },
    downloadTSVData () {
      /* eslint-disable no-console */
      // console.log('downloadTSVData')

      const anchor = document.createElement('a')
      anchor.href = 'data:text/tsv;charset=utf-8,' + encodeURIComponent(this.createTSV())
      anchor.target = '_blank'
      anchor.download = this.formatDateForFilename(this.dayOfInterest) + '_' + this.$t('homePage.dailyTotals') + '_' + this.client.organization.code + '.tsv'
      anchor.click()
    },
    formatDate (value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
    formatDateTime (value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm')
      }
    },
    formatDateForFilename (value) {
      if (value) {
        return moment(String(value)).format('YYYY-MM-DD')
      }
    },
    hideDailyTotals () {
      this.showDailyTotals = false
    },
    getStartTime () {
      if (this.dayOfInterest) {
        if (typeof this.dayOfInterest === 'string' || this.dayOfInterest instanceof String) {
          // console.log('getStartTime : ' + this.dayOfInterest)
          const date = new Date(this.dayOfInterest)
          return date ? date.getTime() : new Date().getTime()
        } else {
          return this.dayOfInterest.getTime()
        }
      } else {
        return moment(new Date()).subtract(24, 'hours').toDate()
      }
    },
    loadDailyTotals () {
      this.clearError()
      this.isLoading = true
      // this.showHiddenFacilities = true
      this.$store.commit('clearError')
      const date = this.getStartTime()
      const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
      const month = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(date)
      const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
      var monthString = `0${month}`
      if (month > 9) {
        monthString = `${month}`
      }
      /* eslint-disable no-console */
      // console.log('loadDailyTotals: ' + `${year}-${monthString}-${day}`)
      const payload = { dateString: `${year}-${monthString}-${day}`, formatId: 0 }
      this.$store.dispatch('getDailyTotals', payload)
      this.dailyTotals = this.$store.dailyTotals
      this.showDailyTotals = true
      this.$forceUpdate()
    },
    paddedShortDateString (date) {
      const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
      const month = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(date)
      const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
      var monthString = `0${month}`
      if (month > 9) {
        monthString = `${month}`
      }
      return `${year}-${monthString}-${day}`
    },
    loadWeeklyAggregations () {
      this.clearError()
      this.isLoading = true
      this.statsDataLoading = true
      this.$store.commit('clearError')
      var selected = new Date(this.selectedChartDate)
      var fromDate
      if (this.statsDuration === 31) {
        fromDate = moment(selected).subtract(1, 'months').toDate()
      } else {
        fromDate = moment(selected).subtract(this.statsDuration, 'days').toDate()
      }
      const untilDate = moment(selected).toDate()
      /* eslint-disable no-console */
      console.log('loadWeeklyAggregations: ' + this.paddedShortDateString(fromDate))
      // const payload = { cdcSensorId: this.currentSensor, fromDate: `${year}-${monthString}-${day}`, untilDate: `${year}-${monthString}-${day}` }
      const payload = { cdcSensorId: this.currentSensor.id, fromDate: this.paddedShortDateString(fromDate), untilDate: this.paddedShortDateString(untilDate) }
      this.$store.dispatch('getSensorAggregations', payload)
      // this.sensorAggregations = this.$store.sensorAggregations
      // this.$forceUpdate()
    },
    loadLatestCDCDataValues () {
      this.clearError()
      this.isLoading = true
      this.$store.commit('clearError')
      const payload = { organizationId: this.client.organization.id }
      this.$store.dispatch('getLatestCDCDataValues', payload)
      if (this.currentFacility != null) {
        this.facilitiesLocal.forEach(facility => {
          //  update current selected facility
          if (facility.id === this.currentFacility.id) {
            this.currentFacility = facility
          }
        })
      }
      this.$forceUpdate()
    },
    showFacility (facility) {
      this.currentFacility = facility
    },
    showSensor (sensor) {
      const device = this.deviceForSensor(sensor)
      //
      // FIXME! we need an aging test as well!
      //
      if (!this.currentDevice || this.currentDevice.id !== device.id) {
        this.currentDevice = device
        this.loadDataValues()
      }
      this.currentSensor = sensor
      this.sensorMode = true
    },
    backToOverview () {
      this.backToFacility()
      this.currentDevice = null
      this.currentFacility = null
    },
    backToFacility () {
      this.currentSensor = { id: -1, name: '' }
      this.sensorMode = false
    },
    isOutdated (timestamp) {
      if (!timestamp) {
        return true
      }
      const delta = moment().valueOf() - timestamp
      return (delta > 10 * 60 * 1000) // mehr als 10 Minuten?
    },
    maxLastSeen (facility) {
      var max = 0
      if (facility) {
        for (const device of facility.devices) {
          const lastseen = device.lastseen
          if (lastseen) {
            max = Math.max(max, moment(lastseen).valueOf())
          }
        }
      }
      return max
    },
    toggleShowHiddenFacilities () {
      this.showHiddenFacilities = !this.showHiddenFacilities
      this.$forceUpdate()
    },
    facilityDisabledCount () {
      var count = 0
      if (this.facilitiesLocal) {
        for (const facility of this.facilitiesLocal) {
          var isDisabeld = true
          for (const device of facility.devices) {
            if (device.enabled) {
              isDisabeld = false
            }
          }
          if (isDisabeld) {
            count += 1
          }
        }
      }
      return count
    },
    facilityImagePath (facility) {
      if (facility != null && facility.type != null) {
        return '/images/ISO_Building_' + facility.type + '.png'
      }
      return '/images/ISO_Building_0.png'
    },
    facilityIsDisabled (facility) {
      for (const device of facility.devices) {
        if (device.enabled) {
          return false
        }
      }
      return true
    },
    facilityStyleHasAlerts (facility) {
      if (this.facilityIsDisabled(facility)) {
        return 'background: rgba(242,242,242,1);'
      }
      if (this.facilityIsOutdated(facility)) {
        return 'background: rgba(255,128,0,1);'
      }
      // var outdated = this.isOutdated(this.maxLastSeen(facility))
      var counts = { sensorCount: 0, sensorsWithAlerts: 0, sensorsWithCritical: 0, sensorsWithWarning: 0, sensorsWithInfo: 0, sensorsWithNone: 0, sensorWithUnknown: 0 }

      for (const device of facility.devices) {
        if (device.enabled === true) {
          var deviceCount = this.deviceAlertCounts(device)
          counts.sensorCount += deviceCount.sensorCount
          counts.sensorsWithAlerts += deviceCount.sensorsWithAlerts
          counts.sensorsWithCritical += deviceCount.sensorsWithCritical
          counts.sensorsWithWarning += deviceCount.sensorsWithWarning
          counts.sensorsWithInfo += deviceCount.sensorsWithInfo
          counts.sensorsWithNone += deviceCount.sensorsWithNone
          counts.sensorWithUnknown += deviceCount.sensorWithUnknown
        }
      }
      /* eslint-disable no-console */
      // console.log(facility.name)
      /* eslint-disable no-console */
      // console.log(counts)
      return 'background:' + this.buildGradientStyle(counts)
    },
    facilityHasAlerts (facility) {
      if (this.facilityIsDisabled(facility)) {
        return false
      }
      var outdated = this.isOutdated(this.maxLastSeen(facility))
      var devicesWithAlerts = 0
      var deviceCount = 1 // 1 is for the facility
      if (!outdated) {
        for (const device of facility.devices) {
          deviceCount += 1
          if (this.deviceIsOutdated(device)) {
            devicesWithAlerts += 1
          }
        }
        // building the gradien for the facility bg
        var buildGradient = 'linear-gradient(90deg'

        if (devicesWithAlerts === 0) {
          buildGradient += ', rgba(127,232,127,1) 0%, rgba(127,232,127,1) 100%' // will not be used
        } else {
          buildGradient += ', rgba(251,199,66,1) 0% , rgba(251,199,66,1) ' + (((devicesWithAlerts / deviceCount) * 100)) + '%'
          buildGradient += ', rgba(127,232,127,1) 100%)' // facility is available (green)
          this.facilityGradient = buildGradient
        }
      }

      return devicesWithAlerts > 0
    },
    facilityIsOutdated (facility) {
      if (this.facilityIsDisabled(facility)) {
        return false
      }
      var outdated = this.isOutdated(this.maxLastSeen(facility))
      /*
      if (!outdated) {
        for (const device of facility.devices) {
          if (this.deviceIsOutdated(device)) {
            outdated = true
            break
          }
        }
      }
      */
      return outdated
    },
    fromNowFacilityDisplay (facility) {
      const max = this.maxLastSeen(facility)
      return max > 0 ? moment(max).fromNow() : '?'
    },
    visibleDevices (facility) {
      const devices = []
      facility.devices.forEach(device => {
        if (device.enabled) {
          devices.push(device)
        }
      })
      return devices
    },
    deviceName (device) {
      return device.name ? device.name : device.apikey
    },
    facilityAlertIcon (facility) {
      var icon = 'blank'
      if (this.facilityIsDisabled(facility)) {
        icon = 'icloud.slash'
      } else {
        for (const device of facility.devices) {
          const lastseen = device.lastseen
          if (lastseen && this.isOutdated(moment(lastseen).valueOf())) {
            return '/images/exclamationmark.bubble.png'
          }
          var sensorWithHighestAlert = null
          var highestReason = -1
          for (const sensor of device.sensors) {
            if (sensor.enabled === true) {
              const reason = this.sensorAlertReason(sensor)
              if (sensorWithHighestAlert === null || reason > highestReason) {
                highestReason = reason
                sensorWithHighestAlert = sensor
              }
            }
          }
        }
        return this.sensorAlertIcon(sensorWithHighestAlert)
      }
      return '/images/' + icon + '.png'
    },
    /*
    deviceAlertIcon (device) {
      var icon = 'blank'
      if (!device.enabled) {
        icon = 'icloud.slash'
      } else {
        var outdated = true
        const lastseen = device.lastseen
        if (lastseen) {
          outdated = this.isOutdated(moment(lastseen).valueOf())
        }
        if (outdated === true) {
          icon = 'exclamationmark.bubble'
        } else {
          return this.deviceAlertReason(device).icon
        }
      }
      return '/images/' + icon + '.png'
    },
    */
    facilityHideReasonNumber (facility) {
      const facilityAlertReason = this.facilityAlertReasons(facility)
      /* eslint-disable no-console */
      // console.log(facilityAlertReason)
      if (facilityAlertReason.reasons.length < 2) { // if there are 0 or 1 reasons don't show
        /* eslint-disable no-console */
        // console.log('Do not show')
        return true
      }
      return false
    },
    facilityAlertReasons (facility) {
      if (this.facilityIsDisabled(facility)) {
        const icon = '/images/icloud.slash.png'
        return { reasons: [AlertReason.DEVICE_DISABLED], highestReason: AlertReason.DEVICE_DISABLED, icon: icon, text: this.alertReasonText(AlertReason.DEVICE_DISABLED) }
      } else {
        var facilityAlertReasons = { reasons: [], highestReason: -1, icon: '/images/blank.png', text: '' }
        for (const device of facility.devices) {
          if (device.enabled === true) {
            const deviceAlertReason = this.deviceAlertReason(device)
            facilityAlertReasons.reasons.push(...deviceAlertReason.reasons)
            if (facilityAlertReasons.highestReason < deviceAlertReason.highestReason) {
              facilityAlertReasons.highestReason = deviceAlertReason.highestReason
              facilityAlertReasons.icon = deviceAlertReason.icon
              facilityAlertReasons.text = deviceAlertReason.text
            }
          }
        }

        return facilityAlertReasons
      }
    },
    deviceHideReasonNumber (device) {
      const deviceAlertReason = this.deviceAlertReason(device)
      if (deviceAlertReason.reasons.length < 2) { // if there are 0 or 1 reasons don't show
        return true
      }
      return false
    },
    deviceAlertReason (device) {
      if (!device.enabled) {
        const icon = '/images/icloud.slash.png'
        return { reasons: [AlertReason.DEVICE_DISABLED], highestReason: AlertReason.DEVICE_DISABLED, icon: icon, text: this.alertReasonText(AlertReason.DEVICE_DISABLED) }
      } else {
        var outdated = true
        const lastseen = device.lastseen
        if (lastseen) {
          outdated = this.isOutdated(moment(lastseen).valueOf())
        }
        if (outdated === true) {
          const icon = '/images/exclamationmark.bubble.png'
          return { reasons: [AlertReason.DEVICE_CONTACT], highestReason: AlertReason.DEVICE_CONTACT, icon: icon, text: this.alertReasonText(AlertReason.DEVICE_CONTACT) }
        }
        var sensorWithHighestAlert = null
        var highestReason = -1
        var reasons = []
        for (const sensor of device.sensors) {
          if (sensor.enabled === true) {
            const reason = this.sensorAlertReason(sensor)
            if (reason > highestReason) {
              highestReason = reason
              sensorWithHighestAlert = sensor
            }
            if (reason !== AlertReason.NONE && reason !== AlertReason.DEVICE_DISABLED) {
              reasons.push(reason)
            }
          }
        }
        return { reasons: reasons, highestReason: highestReason, icon: this.sensorAlertIcon(sensorWithHighestAlert), text: this.sensorAlertReasonText(sensorWithHighestAlert) }
      }
    },
    deviceStyleHasAlerts (device) {
      if (device == null || device.enabled === false) {
        return 'background: rgba(242,242,242,1) 100%);'
      }
      var counts = this.deviceAlertCounts(device)
      /* eslint-disable no-console */
      // console.log(counts)
      return 'background:' + this.buildGradientStyle(counts)
    },
    deviceAlertCounts (device) {
      var sensorsWithAlerts = 0
      var sensorsWithCritical = 0
      var sensorsWithWarning = 0
      var sensorsWithInfo = 0
      var sensorsWithNone = 0
      var sensorWithUnknown = 0
      var sensorCount = 0
      for (const sensor of device.sensors) {
        if (sensor.enabled === true) {
          sensorCount += 1
          var result = this.sensorAlertStatus(sensor)
          switch (result) {
            case -1: sensorWithUnknown += 1
              break
            case 0: sensorsWithNone += 1
              break
            case 1: sensorsWithInfo += 1
              break
            case 2: sensorsWithWarning += 1
              break
            case 3: sensorsWithCritical += 1
              break
            default:
              break
          }
        }
      }
      var outdated = true
      const lastseen = device.lastseen
      if (lastseen) {
        outdated = this.isOutdated(moment(lastseen).valueOf())
        // we handling the device like a sensor
        if (!outdated) {
          /* eslint-disable no-console */
          // console.log('device is not outdated: ' + device.name)
          sensorsWithNone += 1
          sensorCount += 1
        } else {
          /* eslint-disable no-console */
          // console.log('device is outdated: ' + device.name)
          return { sensorCount: 1, sensorsWithAlerts: 1, sensorsWithCritical: 0, sensorsWithWarning: 1, sensorsWithInfo: 0, sensorsWithNone: 0, sensorWithUnknown: 0 }
        }
      } else {
        /* eslint-disable no-console */
        // console.log('no lastseen: ' + device.name)
      }
      sensorsWithAlerts = sensorsWithCritical + sensorsWithWarning + sensorsWithInfo
      return { sensorCount: sensorCount, sensorsWithAlerts: sensorsWithAlerts, sensorsWithCritical: sensorsWithCritical, sensorsWithWarning: sensorsWithWarning, sensorsWithInfo: sensorsWithInfo, sensorsWithNone: sensorsWithNone, sensorWithUnknown: sensorWithUnknown }
    },
    deviceHasAlerts (device) {
      if (!device.enabled) {
        return false
      }
      var outdated = true
      const lastseen = device.lastseen
      if (lastseen) {
        outdated = this.isOutdated(moment(lastseen).valueOf())
      }
      // outdated is already covered we check for Alerts
      if (!outdated) {
        this.deviceGradient = this.buildGradientStyle(this.deviceAlertCounts(device))
        return true
      }
      return false
    },
    buildGradientStyle (counts) {
      /* eslint-disable no-console */
      // console.log('buildGradientStyle: ' + counts.sensorsWithAlerts)

      if (counts.sensorsWithAlerts === 0) {
        return 'background: rgba(127,232,127,1);'
      }
      // building the gradient for the device bg
      var buildGradient = 'linear-gradient(90deg'

      // Beginning
      if (counts.sensorsWithCritical > 0) {
        buildGradient += ', rgba(228,0,0,1) 0%'
      } else if (counts.sensorsWithWarning > 0) {
        buildGradient += ', rgba(255,128,0,1) 0%'
      } else if (counts.sensorsWithInfo > 0) {
        buildGradient += ', rgba(255,181,3,1) 0%'
      } else if (counts.sensorsWithNone > 0) {
        buildGradient += ', rgba(127,232,127,1) 0%'
      }
      // Mid
      if (counts.sensorsWithCritical > 0) {
        buildGradient += ', rgba(255,128,0,1) ' + ((counts.sensorsWithCritical / counts.sensorCount) * 100) + '%'
      }
      if (counts.sensorsWithWarning > 0) {
        buildGradient += ', rgba(255,181,3,1) ' + (((counts.sensorsWithCritical + counts.sensorsWithWarning) / counts.sensorCount) * 100) + '%'
      }
      if (counts.sensorsWithInfo > 0) {
        buildGradient += ', rgba(127,232,127,1) ' + (((counts.sensorsWithCritical + counts.sensorsWithWarning + counts.sensorsWithInfo) / counts.sensorCount) * 100) + '%'
      }
      // End
      if (counts.sensorsWithNone > 0) {
        /* eslint-disable no-console */
        // console.log(counts)

        buildGradient += ', rgba(127,232,127,1) 100%)' // some NONE (green)
      } else {
        buildGradient += ', rgba(255,181,3,1) 100%)'
      }
      return buildGradient
    },
    deviceIsOutdated (device) {
      if (!device.enabled) {
        return false
      }
      var outdated = true
      const lastseen = device.lastseen
      if (lastseen) {
        outdated = this.isOutdated(moment(lastseen).valueOf())
      }
      // although the device might be ok a single sensor may be not!
      if (!outdated) {
        for (const sensor of device.sensors) {
          if (this.sensorIsOutdated(sensor)) {
            outdated = true
            break
          }
        }
      }
      return outdated
    },
    fromNowDeviceDisplay (device) {
      const lastseen = device ? device.lastseen : null
      return lastseen ? moment(lastseen).fromNow() : '?'
    },
    recordedtime (sensor) {
      const data = sensor ? sensor.data : null
      if (data && data.length > 0) {
        const entry = data[0]
        if (entry && entry.recordedtime) {
          return entry.recordedtime
        }
      }
      return null
    },
    deviceForSensor (sensor) {
      if (sensor && this.currentFacility) {
        for (const device of this.currentFacility.devices) {
          if (device.id === sensor.cdcdeviceid) {
            return device
          }
        }
      }
      return null
    },
    visibleSensors (device) {
      const sensors = []
      device.sensors.forEach(sensor => {
        if (sensor.enabled) {
          sensors.push(sensor)
        }
      })
      return objectSorter.sortedArray(sensors, 'asc', 'originalid')
    },
    hasCollections (device) {
      return device.collections && device.collections.length > 0
    },
    visibleCollections (device) {
      const collections = []
      device.collections.forEach(collection => {
        if (collection.name && collection.name.length > 0) {
          collection.enabled = true // artificial!!!
          collection.type = collection.sensortype
          collection.cdcdeviceid = device.id // FIXME!!! should be ommited
          // Lookup of model-type in string table
          collection.model = this.$t('sensorModel.' + collection.modeltype)
          collections.push(collection)
        }
      })
      return objectSorter.sortedArray(collections, 'asc', 'name')
    },
    sensorIsOutdated (sensor) { // see MER-91
      if (!sensor || !sensor.enabled) {
        return false
      }
      const recordedtime = this.recordedtime(sensor)
      if (!recordedtime) {
        return true
      }

      var deltaMax = sensor.updateinterval / 1000
      if (deltaMax < 60) {
        deltaMax = 60
      }
      /* eslint-disable no-console */
      // console.log('deltaMax: ' + deltaMax)
      if (deltaMax === 60) {
        switch (sensor.model) {
          case 'CS-IRP':
          case 'CS-IRPL':
          case 'CS-IRW':
          case 'CS-HG':
            break
          case 'CS-HWL':
          case 'CS-HGL':
          case 'CS-HG_L':
          case 'CS-WGL':
          case 'CS-IRFL':
          case 'CS-IRWL':
            deltaMax = 3600
            break
          case 'CS-T':
          case 'CS-T_L':
          case 'CS-T1L':
          case 'CS-T2L':
            deltaMax = 600
            break
          case 'CS-RS2':
          case 'CS-RS4':
          case 'CS-IRH':
            break
          case 'CS-IRHL':
            deltaMax = 600
            break
          case 'CS-CT':
          case 'CS-IRF':
            break
          case 'CS-MBM':
          case 'CS-FLL':
          case 'CS-FML':
          case 'CS-AQL':
            deltaMax = 600
            break
          case 'CS-IRMB':
          case 'CS-IRMBL':
            deltaMax = 43200
            break
          default:
            break
        }
      }
      // we give a bit more time for sensors with 60s
      if (deltaMax === 60) {
        deltaMax = deltaMax * 3 * 1.1 * 1000
      } else {
        deltaMax = deltaMax * 2 * 1.1 * 1000
      }
      const delta = moment().valueOf() - moment(recordedtime).valueOf() // milliseconds
      return delta > deltaMax
    },
    sensorStyleAlertStatus (sensor) {
      var result = this.sensorAlertStatus(sensor)
      switch (result) {
        case -1: return 'background: linear-gradient(90deg, rgba(127,232,127,1) 0%, rgba(255,181,3,1) 100%);'
        case 0: return 'background: rgba(127,232,127,1);'
        case 1: return 'background: rgba(255,181,3,1);'
        case 2: return 'background: rgba(255,128,0,1);'
        case 3: return 'background: rgba(228,0,0,1);'
        default:
          return 'background: rgba(127,232,127,1);'
      }
    },
    sensorAlertStatus (sensor) {
      const reason = this.sensorAlertReason(sensor)
      /* eslint-disable no-console */
      // console.log('sensorAlertStatus: ' + reason)
      switch (reason) {
        case AlertReason.SENSOR_ALERT:
          return AlertState.CRITICAL
        case AlertReason.STALE_DATA:
          return AlertState.INFO
        case AlertReason.BATTERY_LOW:
          return AlertState.WARNING
        case AlertReason.DEVICE_CONTACT:
          return AlertState.INFO
        case AlertReason.SENSOR_DISABLED:
          return AlertState.UNKNOWN
        case AlertReason.USAGE_LIMIT:
          return AlertState.WARNING
        case AlertReason.NO_SENSORDATA:
          return AlertState.WARNING
        case AlertReason.SENSOR_EVENT:
          return AlertState.UNKNOWN // getHighestWarningFromEvent(viewModel: viewModel)
        case AlertReason.NONE:
          return AlertState.OK
        default:
          return AlertState.INFO
      }
    },
    sensorAlertReasonText (sensor) {
      return this.alertReasonText(this.sensorAlertReason(sensor))
    },
    alertReasonText (alertReason) {
      switch (alertReason) {
        case AlertReason.NONE: return this.$t('sensorAlertReason.None')
        case AlertReason.DEVICE_DISABLED: return this.$t('sensorAlertReason.DeviceIsDisabled')
        case AlertReason.DEVICE_CONTACT: return this.$t('sensorAlertReason.NoContactToDevice')
        case AlertReason.STALE_DATA: return this.$t('sensorAlertReason.StaleData')
        case AlertReason.BATTERY_LOW: return this.$t('sensorAlertReason.BatteryIsLow')
        case AlertReason.USAGE_LIMIT: return this.$t('sensorAlertReason.UsageLimitReached')
        case AlertReason.SENSOR_WARNING: return this.$t('sensorAlertReason.SensorWarning')
        case AlertReason.SENSOR_ALERT: return this.$t('sensorAlertReason.SensorAlert')
        case AlertReason.SENSOR_DISABLED: return this.$t('sensorAlertReason.SensorIsDisbled')
        case AlertReason.NO_SENSORDATA: return this.$t('sensorAlertReason.NoSensorData')
        case AlertReason.SENSOR_EVENT: return this.$t('sensorAlertReason.EventWasTriggered')
        case AlertReason.UNKNOWN: return this.$t('sensorAlertReason.Unknown')
        default:
          return this.$t('sensorAlertReason.Unknown')
      }
    },
    sensorAlertReason (sensor) {
      if (!sensor.enabled) {
        return AlertReason.DEVICE_DISABLED
      }
      var valueData = this.findFirstGlanceReading(sensor)

      if (valueData != null) {
        if (sensor.type === 5) { // .WARTERGUARD
          if (valueData.value > 0) {
            return AlertReason.SENSOR_ALERT
          }
        }
        if (sensor.type === 8) { // .AIR
          if (valueData.value >= 7) {
            return AlertReason.SENSOR_ALERT
          }
          if (valueData.value >= 5) {
            return AlertReason.SENSOR_WARNING
          }
        }
        const datapackage = this.dataPackageForSensor(sensor)
        const sensorAlertValue = this.findSensorValueForSystemPartAndType(sensor, { systempart: 30, valuetype: 31 }, datapackage)
        if (sensorAlertValue && sensorAlertValue.factor !== 0) {
          return AlertReason.SENSOR_WARNING
        }
        if (this.sensorIsOutdated(sensor)) {
          return AlertReason.STALE_DATA
        }
        return AlertReason.NONE
      }
      if (this.sensorIsOutdated(sensor)) {
        return AlertReason.STALE_DATA
      } else {
        return AlertReason.NONE
      }
    },
    fromNowSensorDisplay (sensor) {
      const recordedtime = this.recordedtime(sensor)
      return recordedtime ? moment(recordedtime).fromNow() : '?'
    },
    totalFromNowSensorDisplay (sensor) {
      const recordedtime = sensor.total.lastupdate
      return recordedtime ? moment(recordedtime).fromNow() : '?'
    },
    // private
    findSensorValueForSystemPartAndType (sensor, pair, datapackage) {
      if (sensor && datapackage) {
        for (const value of datapackage.values) {
          if (value.systempart === pair.systempart && value.type === pair.valuetype) {
            return value
          }
        }
      }
      return null
    },
    // private
    dataPackageForSensor (sensor) {
      return sensor && sensor.data && sensor.data.length > 0 ? sensor.data[0] : null
    },
    // private func returning single cdc-value object or null
    findFirstGlanceReadingInDataPackage (sensor, datapackage) {
      const pair = this.firstGlanceSystemPartAndType(sensor.type)
      return this.findSensorValueForSystemPartAndType(sensor, pair, datapackage)
    },
    // private
    findFirstGlanceReading (sensor) {
      const datapackage = this.dataPackageForSensor(sensor)
      return datapackage ? this.findFirstGlanceReadingInDataPackage(sensor, datapackage) : null
    },
    // private func return array of cdc-value objects or empty
    findFallbackMeasurementsForElectricityFirstGlanceReadingInDataPackage (sensor, datapackage) {
      const array = []
      if (datapackage) {
        const pairs = [{ systempart: 1, valuetype: 3 }, { systempart: 2, valuetype: 3 }, { systempart: 3, valuetype: 3 }]
        for (const pair of pairs) {
          const value = this.findSensorValueForSystemPartAndType(sensor, pair, datapackage)
          /* eslint-disable no-console */
          // console.log('pair: ' + JSON.stringify(pair) + ' value: ' + JSON.stringify(value))
          if (value) {
            array.push(value)
          }
        }
      }
      return array
    },
    // private
    findFallbackMeasurementsForElectricityFirstGlanceReading (sensor) {
      const datapackage = this.dataPackageForSensor(sensor)
      return datapackage ? this.findFallbackMeasurementsForElectricityFirstGlanceReadingInDataPackage(sensor, datapackage) : []
    },
    // private
    findFallbackMeterReadingMeasurementsForElectricityFirstGlanceReading (sensor) {
      const pair = { systempart: 4, valuetype: 9 }
      const datapackage = this.dataPackageForSensor(sensor)
      return this.findSensorValueForSystemPartAndType(sensor, pair, datapackage)
    },
    // private
    findSinglePhaseFallbackMeasurementsForElectricityFirstGlanceReadingInDataPackage (sensor, datapackage) {
      const pair = { systempart: 0, valuetype: 3 }
      return this.findSensorValueForSystemPartAndType(sensor, pair, datapackage)
    },
    // private
    findSinglePhaseFallbackMeasurementsForElectricityFirstGlanceReading (sensor) {
      const datapackage = this.dataPackageForSensor(sensor)
      return datapackage ? this.findSinglePhaseFallbackMeasurementsForElectricityFirstGlanceReadingInDataPackage(sensor, datapackage) : null
    },
    // private
    nonFirstGlanceValues (sensor) {
      const pair = this.firstGlanceSystemPartAndType(sensor.type)
      return this.nonFirstGlanceValuesForSystemPartAndType(sensor, pair)
    },
    // private
    nonFirstGlanceValuesForSystemPartAndType (sensor, pair) {
      const list = []
      const datapackage = this.dataPackageForSensor(sensor)
      if (!datapackage || datapackage.values.length === 0) {
        return list
      }
      for (const value of datapackage.values) {
        if (value.systempart === pair.systempart && value.type === pair.valuetype) {
          continue
        }
        if (sensor.type === 0) { // power
          if (value.type === 3 && value.systempart >= 0 && value.systempart <= 3) {
            continue
          }
        }
        list.push(value)
      }
      return objectSorter.sortedArray(list, 'asc', 'systempart')
    },
    // private
    sensorsPossibleSystemPartsAndTypes (sensorType) {
      var systemPartsAndTypes = []

      switch (sensorType) {
        case 0: // power
          systemPartsAndTypes.push({ systempart: 4, valuetype: 3 })
          systemPartsAndTypes.push({ systempart: 4, valuetype: 9 })
          break
        case 1: // gas
          systemPartsAndTypes.push({ systempart: 4, valuetype: 13 })
          break
        case 2: // water
          systemPartsAndTypes.push({ systempart: 4, valuetype: 26 })
          break
        case 3: // heat
          systemPartsAndTypes.push({ systempart: 4, valuetype: 9 })
          break
        case 4: // temperature
          systemPartsAndTypes.push({ systempart: 20, valuetype: 16 })
          break
        case 5: // flooding
          systemPartsAndTypes.push({ systempart: 4, valuetype: 21 })
          break
        case 6: // pressure
          systemPartsAndTypes.push({ systempart: 4, valuetype: 24 })
          break
        case 8: // air
          systemPartsAndTypes.push({ systempart: 4, valuetype: 30 })
          break
        case 9: // generic aka collection
          systemPartsAndTypes.push({ systempart: 4, valuetype: 9 })
          break
        case 10: // environmental
          systemPartsAndTypes.push({ systempart: 28, valuetype: 15 })
          break
        default:
          break
      }
      return systemPartsAndTypes
    },
    // private
    firstGlanceSystemPartAndType (sensorType) {
      var systemPart = -1
      var valueType = -1

      switch (sensorType) {
        case 0: // power
          systemPart = 4; valueType = 3
          break
        case 1: // gas
          systemPart = 4; valueType = 13
          break
        case 2: // water
          systemPart = 4; valueType = 26
          break
        case 3: // heat
          systemPart = 4; valueType = 9
          break
        case 4: // temperature
          systemPart = 20; valueType = 16
          break
        case 5: // flooding
          systemPart = 4; valueType = 21
          break
        case 6: // pressure
          systemPart = 4; valueType = 24
          break
        case 8: // air
          systemPart = 4; valueType = 30
          break
        case 9: // generic aka collection
          systemPart = 4; valueType = 9 // FIXME!! only preliminary
          break
        case 10: // environmental
          systemPart = 28; valueType = 15
          break
        default:
          break
      }
      return { systempart: systemPart, valuetype: valueType }
    },
    providesFirstGlanceData (sensor) {
      const pair = this.firstGlanceSystemPartAndType(sensor.type)
      return (pair.systempart > -1 && pair.valuetype > -1)
    },
    collectionIcon (collection) {
      return this.sensorIcon(collection)
    },
    sensorAlertIcon (sensor) {
      var icon = 'blank'
      if (sensor) {
        const reason = this.sensorAlertReason(sensor)
        /* eslint-disable no-console */
        // console.log('sensorAlertReason: ' + reason)
        switch (reason) {
          case AlertReason.SENSOR_ALERT:
            icon = 'exclamationmark.bubble'
            break
          case AlertReason.STALE_DATA:
            icon = 'network.slash'
            break
          case AlertReason.BATTERY_LOW:
            icon = 'battery.25'
            break
          case AlertReason.DEVICE_CONTACT:
            icon = 'network.slash'
            break
          case AlertReason.SENSOR_DISABLED:
            icon = 'icloud.slash'
            break
          case AlertReason.USAGE_LIMIT:
            icon = 'questionmark.square.dashed'
            break
          case AlertReason.NO_SENSORDATA:
            icon = 'exclamationmark.triangle'
            break
          case AlertReason.SENSOR_EVENT:
            icon = 'bell.badge'
            break
          case AlertReason.NONE:
            icon = 'blank'
            break
          default:
            icon = 'exclamationmark.bubble'
        }
      }
      return '/images/' + icon + '.png'
    },
    sensorTypeLocalized (type) {
      switch (type) {
        case 0:
          return this.$t('homePage.type0')
        case 1:
          return this.$t('homePage.type1')
        case 2:
          return this.$t('homePage.type2')
        case 3:
          return this.$t('homePage.type3')
        case 4:
          return this.$t('homePage.type4')
        case 5:
          return this.$t('homePage.type5')
        case 6:
          return this.$t('homePage.type6')
        case 7:
          return this.$t('homePage.type7')
        case 8:
          return this.$t('homePage.type8')
        case 9:
          return this.$t('homePage.type9')
        case 10:
          return this.$t('homePage.type10')
        default:
          return ''
      }
    },
    sensorTypeIcon (type) {
      var icon = 'electricity'
      switch (type) {
        case 0:
          icon = 'sensor_power_circle'
          break
        case 1:
          icon = 'sensor_gas_circle'
          break
        case 2:
          icon = 'sensor_water_circle'
          break
        case 3:
          icon = 'sensor_heat_circle'
          break
        case 4:
          icon = 'sensor_temperature_circle'
          break
        case 5:
          icon = 'sensor_leakage_circle'
          break
        case 6:
          icon = 'sensor_pressure_circle'
          break
        case 7:
          icon = 'sensor_level_circle'
          break
        case 8:
          icon = 'sensor_air_circle'
          break
        case 9:
          icon = 'sensor_generic_circle'
          break
        case 10:
          icon = 'sensor_environment_circle'
          break
        default:
          icon = 'sensor_unknown_circle'
          break
      }
      return '/images/' + icon + '.png'
    },
    sensorIcon (sensor) {
      var icon = 'electricity'
      if (sensor) {
        return this.sensorTypeIcon(sensor.type)
      }
      return '/images/' + icon + '.png'
    },
    sensorSystemDisplay (sensor) {
      var value = this.findFirstGlanceReading(sensor)
      var systemPart = -1
      var valueType = -1
      if (value) {
        systemPart = value.systempart
        valueType = value.type
      } else {
        // fallback for electricity?
        if (sensor.type === 0) {
          const values = this.findFallbackMeasurementsForElectricityFirstGlanceReading(sensor)
          if (values && values.length > 0) {
            systemPart = values[0].systempart
            valueType = values[0].type
          } else {
            // fallback to 0 3
            value = this.findSinglePhaseFallbackMeasurementsForElectricityFirstGlanceReading(sensor)
            // fallback to 4 9 meter reading
            if (!value) {
              value = this.findFallbackMeterReadingMeasurementsForElectricityFirstGlanceReading(sensor)
            }
            if (value) {
              systemPart = value.systempart
              valueType = value.type
            }
          }
        } else if (sensor.type === 1) { // Gas
          systemPart = 4 // CDC_SYSTEM_TOTAL
          valueType = 13 // CDC_M3_MINUTE_TYPE
        } else if (sensor.type === 2) { // Water
          systemPart = 4 // CDC_SYSTEM_TOTAL
          valueType = 26 // CDC_L_MIN_TYPE
        }
      }
      return this.stringForSystemPartAndValueType(systemPart, valueType)
    },
    totalSensorSystemDisplay (sensor) {
      return this.stringForSystemPartAndValueType(sensor.total.systempart, sensor.total.valuetypetotal)
    },
    stringForSystemPartAndValueType (systempart, valuetype) {
      const systemPartText = this.attributeLabelForCode('systemPart', systempart)
      const valueTypeText = this.attributeLabelForCode('valueType', valuetype)
      return systemPartText + ' ' + valueTypeText
    },
    manipulatedUnitDisplay (valuetype, prefix) {
      return prefix + this.attributeLabelForCode('physicalUnit', valuetype)
    },
    unitDisplay (valueObject) {
      if (valueObject) {
        const prefix = this.computeNumericValue(valueObject) > Math.pow(10, 6) ? 'k' : ''
        /* eslint-disable no-console */
        // console.log('unitDisplay 1. valueObject: ' + JSON.stringify(valueObject))
        if (valueObject.type === 31) {
          // show only Sensor Code label when there is a code
          return valueObject.factor > 0 ? this.manipulatedUnitDisplay(valueObject.type, '') : ''
        } else if (valueObject.type === 32 || valueObject.type === 33) {
          // no prefix for certain value types
          return this.manipulatedUnitDisplay(valueObject.type, '')
        }
        /* eslint-disable no-console */
        // console.log('unitDisplay 2. valueObject: ' + JSON.stringify(valueObject))
        return this.manipulatedUnitDisplay(valueObject.type, prefix)
      }
      return ''
    },
    sensorUnitDisplay (sensor) {
      var valueEntry = this.findFirstGlanceReading(sensor)
      if (!valueEntry) {
        // fallback for electricity?
        if (sensor.type === 0) {
          const values = this.findFallbackMeasurementsForElectricityFirstGlanceReading(sensor)
          if (values && values.length > 0) {
            valueEntry = values[0]
          } else {
            valueEntry = this.findSinglePhaseFallbackMeasurementsForElectricityFirstGlanceReading(sensor)
            if (!valueEntry) {
              valueEntry = this.findFallbackMeterReadingMeasurementsForElectricityFirstGlanceReading(sensor)
            }
          }
        } else if (sensor.type === 1) { // Gas
          valueEntry = 13 // CDC_M3_MINUTE_TYPE
          return this.manipulatedUnitDisplay(valueEntry, '')
        } else if (sensor.type === 2) { // Water
          valueEntry = 26 // CDC_L_MIN_TYPE
          return this.manipulatedUnitDisplay(valueEntry, '')
        }
      }
      return this.unitDisplay(valueEntry)
    },
    totalSensorUnitDisplay (sensor) {
      const prefix = this.computeNumericValue(sensor.total) > Math.pow(10, 6) ? 'k' : ''
      return this.manipulatedUnitDisplay(sensor.total.valuetypetotal, prefix)
    },
    formattedValue (valueEntry, valueType) {
      if (valueType !== 31 && valueType !== 32 && valueType !== 33) {
        var value = this.computeNumericValue(valueEntry)
        const decimals = (valueType === 13 || valueType === 14) ? 3 : 1
        if (value > Math.pow(10, 6)) {
          value /= 1000.0
        }
        value = value.toFixed(decimals)
        return parseFloat(value).toLocaleString('de-DE')
      } else {
        if (valueType === 31) { // Sensor CDC_ALERT_TYPE
          const alertTypeNames = ['UNBEKANNT', 'NORMAL', 'TEST', 'INFO', 'NOTICE', 'WARNING', 'ERROR', 'CRITICAL', 'EMERGENCY']
          const sensorAlertCode = valueEntry.value // this.computeNumericValue(valueEntry))
          const code = Number(valueEntry.factor & 0xff)

          /* eslint-disable no-console */
          // console.log('sensorAlertCode: ' + sensorAlertCode + ' meterCode: ' + code)
          const index = sensorAlertCode < 7 ? sensorAlertCode : 0
          return alertTypeNames[index] + (code > 0 ? ' (' + code + ') ' : '')
        }
        return parseFloat(valueEntry.value).toLocaleString('de-DE')
      }
    },
    sensorValueDisplay (sensor) {
      var valueEntry = this.findFirstGlanceReading(sensor)
      /* eslint-disable no-console */
      // console.log('sensorValueDisplay1 valueEntry: ' + JSON.stringify(valueEntry))
      if (valueEntry) {
        return this.formattedValue(valueEntry, valueEntry.type)
      } else {
        const valueObjects = this.findFallbackMeasurementsForElectricityFirstGlanceReading(sensor)
        /* eslint-disable no-console */
        // console.log('sensorValueDisplay2 valueEntry: ' + JSON.stringify(valueEntry))

        if (valueObjects && valueObjects.length > 0) {
          // const first = valueObjects[0]
          var sum = 0.0
          var hasSum = false
          var total = 0.0
          for (const valueObject of valueObjects) {
            if (valueObject.type === 3) {
              sum = sum + this.computeNumericValue(valueObject)
              hasSum = true
            } else if (valueObject.type === 4) {
              total = this.computeNumericValue(valueObject)
            }
          }
          return this.formattedValue({ value: hasSum ? sum : total, factor: 0 }, hasSum ? 3 : 4) // first.type)
        } else {
          valueEntry = this.findSinglePhaseFallbackMeasurementsForElectricityFirstGlanceReading(sensor)
          // fallback single phase
          if (valueEntry) {
            return this.formattedValue(valueEntry, valueEntry.type)
          }
          // fallback meter reading
          valueEntry = this.findFallbackMeterReadingMeasurementsForElectricityFirstGlanceReading(sensor)
          if (valueEntry) {
            return this.formattedValue(valueEntry, valueEntry.type)
          }
        }
      }
      return '?'
    },
    totalSensorValueDisplay (sensor) {
      return this.formattedValue(sensor.total, sensor.total.valuetypetotal)
    },
    totalSensorAccuracyDisplay (sensor) {
      if (sensor && sensor.total) {
        const packagecount = sensor.total.packagecount
        const missedpackages = sensor.total.missedpackages
        const ratio = packagecount > 0 ? missedpackages / (packagecount + missedpackages) * 100.0 : 100.0
        return ratio.toFixed(1)
      }
      return '?'
    },
    sensorHasTotal (sensor) {
      return sensor && sensor.total
    },
    sensorHasTotalSinceStart (sensor) {
      return sensor && sensor.total && sensor.total.systempart === 26
    },
    manualMeterReading (sensor) {
      this.openModal('#editSensorTotalModal')
    },
    // private
    computeNumericValue (sensorValue) {
      if (sensorValue) {
        return sensorValue.value * Math.pow(10, sensorValue.factor)
      }
      return 0.0
    },
    // data for graph
    loadDataValues () {
      this.isLoading = true
      this.chartDataLoading = true
      const startTS = moment(this.chartStartDate).valueOf()
      const endTS = moment(this.chartEndDate).valueOf()
      const payload = { cdcDeviceId: this.currentDevice.id, fromDate: startTS, untilDate: endTS }
      this.$store.commit('clearError')
      this.$store.dispatch('getCDCDataValuesSlim', payload)
    },
    chartOptions () {
      var radius = 2
      var borderWidth = 2
      if (this.chartDuration > 1) {
        radius = 0
        borderWidth = 1
      }
      const options = {
        // backgroundColor: 'rgba(102,129,194,.5)',
        fill: {
          target: 'origin',
          above: 'rgba(71, 183,132,.5)',
          below: 'rgba(71, 183,132,.1)'
        },
        elements: {
          point: {
            radius: radius
          },
          line: {
            borderWidth: borderWidth
          }
        },
        scales: {
          x: {
            type: 'time',
            offset: true,
            gridLines: { offsetGridLines: true },
            time: { displayFormats: { minute: 'HH:mm' } },
            ticks: {
              display: false // this will remove only the label
            },
            display: true
          }
        }
      }
      /* eslint-disable no-console */
      // console.log(options)
      return options
    },
    barChartDataSet () {
      // TODO: implement preferences
      const preferences = this.client.preferences
      var backgroundColor = 'rgba(102,129,194,.5)'
      var borderColor = 'rgba(102,129,194,1)' // '#6695C2'
      var borderWidth = 2
      if (preferences.barBackgroundColor) {
        backgroundColor = preferences.barBackgroundColor
      }
      if (preferences.barborderWidth) {
        borderWidth = preferences.barborderWidth
      }
      if (preferences.barBorderWidth) {
        borderWidth = preferences.barBorderWidth
      }
      const dataset = {
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        borderWidth: borderWidth,
        borderRadius: 5
        // barPercentage: 0.5,
        // barThickness: 6,
        // maxBarThickness: 8
      }
      /* eslint-disable no-console */
      // console.log(dataset)
      return dataset
    },
    areaChartDataSet () {
      const preferences = this.client.preferences
      /* eslint-disable no-console */
      // console.log(preferences)
      var backgroundColor = 'rgba(102,129,194,.5)'
      var borderColor = 'rgba(102,129,194,1)' // '#6695C2'
      var borderWidth = 2
      if (preferences.areaBackgroundColor) {
        backgroundColor = preferences.areaBackgroundColor
      }
      if (preferences.areaBorderColor) {
        borderColor = preferences.areaBorderColor
      }
      if (preferences.areaBorderWidth) {
        if (this.chartDuration > 1) {
          borderWidth = 1
        } else {
          borderWidth = preferences.areaBorderWidth
        }
      }
      const dataset = {
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        borderWidth: borderWidth
        /*
        fill: {
          target: 'origin',
          above: 'rgba(71, 183,132,.5)',
          below: 'rgba(71, 183,132,.1)'
        }
        */
        // barPercentage: 0.5,
        // barThickness: 6,
        // maxBarThickness: 8
      }
      /* eslint-disable no-console */
      // console.log(dataset)
      return dataset
    },
    statsDataSets (sensor) {
      const dataSets = []
      if (this.sensorAggregations.aggregations && this.sensorAggregations.aggregations.length > 0) {
        const aDataPackackage = this.sensorAggregations.aggregations[0]
        const dataExp = this.aggregationsData('exp')
        const dataImp = this.aggregationsData('imp')

        const preferences = this.client.preferences
        var backgroundColor = 'rgba(102,129,194,.5)'
        var borderColor = 'rgba(102,129,194,1)' // '#6695C2'
        var borderWidth = 2
        if (preferences.barBackgroundColor) {
          backgroundColor = preferences.barBackgroundColor
        }
        if (preferences.barborderWidth) {
          borderWidth = preferences.barborderWidth
        }
        if (preferences.barBorderWidth) {
          borderWidth = preferences.barBorderWidth
        }
        /* eslint-disable no-console */
        // console.log(labels)
        var hasValues = false
        if (dataExp && Object.keys(dataExp).length > 0) {
          const prefix = aDataPackackage.dailyexpvt === 10 ? 'k' : '' // 9 and 10 will be in aggregationsData changed to kWh
          const graph = {
            type: 'bar',
            name: this.stringForSystemPartAndValueType(aDataPackackage.dailyexpsp, aDataPackackage.dailyexpvt) + ' (' + this.manipulatedUnitDisplay(aDataPackackage.dailyexpvt, prefix) + ')', // 'Export', // title,
            backgroundColor: backgroundColor, // 'rgba(F3, A6, 93,.5)',
            borderColor: borderColor, // '#F3A693',
            borderWidth: borderWidth,
            data: dataExp
          }
          hasValues = true
          dataSets.push(graph)
        }
        if (dataImp && Object.keys(dataImp).length > 0) {
          const prefix = aDataPackackage.dailyimpvt === 9 ? 'k' : '' // 9 and 10 will be in aggregationsData changed to kWh
          const graph = {
            type: 'bar',
            name: this.stringForSystemPartAndValueType(aDataPackackage.dailyimpsp, aDataPackackage.dailyimpvt) + ' (' + this.manipulatedUnitDisplay(aDataPackackage.dailyimpvt, prefix) + ')', // 'Import', // title,
            backgroundColor: backgroundColor, // 'rgba(F3, A6, 93,.5)',
            borderColor: borderColor, // '#F3A693',
            borderWidth: 5, // borderWidth,
            data: dataImp
          }
          hasValues = true
          dataSets.push(graph)
        }
        if (!hasValues) { // if there is no Import / Export value use min, max, avg
          const dataMin = this.aggregationsData('min')
          const dataMax = this.aggregationsData('max')
          const dataAvg = this.aggregationsData('avg')

          if (dataMin && Object.keys(dataMin).length > 0) {
            const graph = {
              type: 'bar',
              name: 'Min.' + this.stringForSystemPartAndValueType(aDataPackackage.systempart, aDataPackackage.valuetype), // 'Import', // title,
              backgroundColor: backgroundColor, // 'rgba(F3, A6, 93,.5)',
              borderColor: borderColor, // '#F3A693',
              borderWidth: borderWidth,
              data: dataMin
            }
            dataSets.push(graph)
          }
          if (dataMax && Object.keys(dataMax).length > 0) {
            const graph = {
              type: 'bar',
              name: 'Max.' + this.stringForSystemPartAndValueType(aDataPackackage.systempart, aDataPackackage.valuetype), // 'Import', // title,
              backgroundColor: backgroundColor, // 'rgba(F3, A6, 93,.5)',
              borderColor: borderColor, // '#F3A693',
              borderWidth: borderWidth,
              data: dataMax
            }
            dataSets.push(graph)
          }
          if (dataAvg && Object.keys(dataAvg).length > 0) {
            const graph = {
              type: 'bar',
              name: 'Avg.' + this.stringForSystemPartAndValueType(aDataPackackage.systempart, aDataPackackage.valuetype), // 'Import', // title,
              backgroundColor: backgroundColor, // 'rgba(F3, A6, 93,.5)',
              borderColor: borderColor, // '#F3A693',
              borderWidth: borderWidth,
              data: dataAvg
            }
            dataSets.push(graph)
          }
        }
      }
      return dataSets
    },
    chartDataSets (sensor) {
      const dataSet = []
      const data = this.firstGlanceData(sensor)
      /* eslint-disable no-console */
      // console.log(data)
      const title = this.sensorSystemDisplay(sensor) + ' (' + this.sensorUnitDisplay(sensor) + ')'

      const labels = []
      /*
      for (const key of Object.keys(data)) {
        const date = new Date(key)
        labels.push(moment(date).format('HH:mm'))
      }
      */
      /* eslint-disable no-console */
      // console.log(labels)
      // const typeString = sensor.type === 1 ? 'bar' : 'line'
      /* eslint-disable no-console */
      // console.log(typeString)
      if (data && Object.keys(data).length > 0) {
        var graph
        if (sensor.type !== 1) {
          graph = {
            type: 'line',
            name: title,
            data: data,
            labels: labels,
            options: {
              legend: { display: false },
              scales: {
                x: {
                  type: 'time',
                  offset: true,
                  gridLines: { offsetGridLines: true },
                  time: { displayFormats: { minute: 'HH:mm' } },
                  ticks: {
                    display: false // this will remove only the label
                  },
                  display: true
                }
              }
            }
          }
        } else {
          graph = {
            type: 'bar',
            name: title,
            backgroundColor: 'rgba(71, 183,132,.5)',
            borderColor: '#47b784',
            borderWidth: 2,
            data: data,
            labels: labels,
            options: {
              legend: { display: true },
              scales: {
                x: {
                  type: 'time',
                  offset: true,
                  gridLines: { offsetGridLines: true },
                  time: { displayFormats: { minute: 'HH:mm' } },
                  ticks: {
                    display: false // this will remove only the label
                  },
                  display: true
                }
              }
            }
          }
        }
        dataSet.push(graph)
      }
      return dataSet
    },
    firstGlanceData (sensor) { // private func
      const data = {}
      var lastTS = moment()
      this.cdcDataSortedByTime.forEach(dataPackage => {
        // select values for this sensor only!
        if (sensor.originalid === dataPackage.sensorpk) {
          var measurement = 0.0
          const value = this.findFirstGlanceReadingInDataPackage(sensor, dataPackage)
          const zeros = this.findSensorValueForSystemPartAndType(sensor, { systempart: 4, valuetype: 18 }, dataPackage)
          /* eslint-disable no-console */
          // console.log(zeros)
          /* eslint-disable no-console */
          // console.log('Zeros')

          // if there where zero counts add them here
          if (zeros) {
            const zeroCount = this.computeNumericValue(zeros)
            for (let i = 1; i <= zeroCount; i++) {
              const stamp = moment(String(dataPackage.recordedtime)).subtract(i, 'minutes')
              if (sensor.type === 1 || sensor.type === 2) {
                data[stamp.format('H:mm')] = 0
              } else {
                data[stamp] = 0
              }
            }
          }
          if (value) {
            measurement = this.computeNumericValue(value)
          } else {
            const values = this.findFallbackMeasurementsForElectricityFirstGlanceReadingInDataPackage(sensor, dataPackage)
            if (values && values.length > 0) {
              for (const value of values) {
                measurement += this.computeNumericValue(value)
              }
            } else {
              const value = this.findSinglePhaseFallbackMeasurementsForElectricityFirstGlanceReadingInDataPackage(sensor, dataPackage)
              if (value) {
                measurement = this.computeNumericValue(value)
              }
            }
          }
          const stamp = moment(String(dataPackage.recordedtime))
          data[stamp.format('H:mm')] = measurement
          /*
          if (sensor.type === 1 || sensor.type === 2) {
            data[stamp.format('HH:mm')] = measurement
          } else {
            data[stamp] = measurement
          }
          */
          lastTS = stamp
        }
      })
      // add zeros to the end when barchart
      if (sensor.type === 1 || sensor.type === 2) {
        var end = moment(this.chartEndDate)
        if (new Date(this.chartEndDate).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0) && this.chartDuration > 1) {
          // is today and 24h display set new Date to have the current time as end
          /* eslint-disable no-console */
          // console.log('today: ' + end)
          end = moment(new Date())
        }
        const diffMinutes = end.diff(lastTS, 'minutes')
        /* eslint-disable no-console */
        // console.log('diffMinutes: ' + diffMinutes)
        for (let minutes = 1; minutes <= diffMinutes; minutes++) {
          const currentTS = lastTS.clone().add(minutes, 'minutes')
          data[currentTS.format('H:mm')] = 0
        }
      }
      return data
    },
    aggregationsData (key) { // private func
      const data = {}
      if (this.sensorAggregations.aggregations) {
        this.sensorAggregations.aggregations.forEach(dataPackage => {
          var measurement
          if (key === 'exp') {
            measurement = dataPackage.dailyexp
          } else if (key === 'max') {
            measurement = dataPackage.max
          } else if (key === 'min') {
            measurement = dataPackage.min
          } else if (key === 'avg') {
            measurement = dataPackage.sum / dataPackage.cnt
          } else {
            measurement = dataPackage.dailyimp
          }
          /* eslint-disable no-console */
          // console.log(dataPackage)
          if (measurement) {
            const stamp = moment(String(dataPackage.date))
            const factor = dataPackage.dailyimpvt === 9 || dataPackage.dailyexpvt === 10 ? -3 : 0 // 4,9 is in Wh make it kWh
            if (factor === -3 && measurement > 25000) {
              data[stamp.format('MMM Do YY')] = Math.round(measurement * Math.pow(10, factor))
            } else {
              var num = Number(measurement * Math.pow(10, factor))
              var roundedString = num.toFixed(2)
              data[stamp.format('MMM Do YY')] = Number(roundedString)
            }
          }
        })
      }
      return data
    },
    showMapSnapshot (facility) {
      if (facility != null) {
        this.setMapFacility(facility)
      }
      this.openModal('#mapSnapshotModal')
    },
    loadDetailedFacilities (reset) {
      /* eslint-disable no-console */
      // console.log('loadDetailedFacilities')

      this.isLoading = true
      const payload = { organizationId: this.selectedOrganization.id }
      this.$store.commit('clearError')
      this.$store.dispatch('getFacilityData', payload)
      //  if (reset) {
      //  this.selectedDetailFacility = this.emptyFacility
      //  }
    },
    openInAppleMap (facility) {
      window.open(this.createMapLink(facility), '_blank')
    },
    hasCoordinates (facility) {
      this.setMapFacility(facility)
      if (this.showMapFacility && this.showMapFacility.geolatitude && this.showMapFacility.geolongitude) {
        return true
      }
      return false
    },
    createMapLink (facility) {
      this.setMapFacility(facility)
      if (this.showMapFacility && this.showMapFacility.geolatitude && this.showMapFacility.geolongitude) {
        const uri = 'https://beta.maps.apple.com/?ll=' + this.showMapFacility.geolatitude + ',' + this.showMapFacility.geolongitude + '&q=' + this.showMapFacility.name
        return encodeURI(uri)
        // return 'maps://q=' + this.showMapFacility.geolatitude + ',' + this.showMapFacility.geolongitude
      }
      return ''
    },
    setMapFacility (aFacility) {
      if (aFacility != null) {
        this.facilities.forEach(facility => {
          //  update current selected facility
          if (facility.id === aFacility.id) { // this.currentFacility.id) {
            this.showMapFacility = facility
          }
        })
      }
    },
    toggleChartDuration () {
      if (this.chartDuration <= 1) {
        this.chartDuration = 24
      } else {
        this.chartDuration = 1
      }
    },
    toggleStatsDuration () {
      if (this.statsDuration <= 7) {
        this.statsDuration = 31
      } else {
        this.statsDuration = 7
      }
    },
    previousChart () {
      if (this.chartViewStatus === 2) {
        this.selectedChartDate = moment(this.selectedChartDate).subtract(this.statsDuration, 'days').toDate()
      } else {
        this.selectedChartDate = moment(this.selectedChartDate).subtract(this.chartDuration, 'hours').toDate()
      }
    },
    nextChart () {
      var newChartDate // = moment(this.selectedChartDate).add(this.chartDuration, 'hours').toDate()
      if (this.chartViewStatus === 2) {
        newChartDate = moment(this.selectedChartDate).add(this.statsDuration, 'days').toDate()
      } else {
        newChartDate = moment(this.selectedChartDate).add(this.chartDuration, 'hours').toDate()
      }
      if (newChartDate > new Date()) {
        this.selectedChartDate = new Date()
      } else {
        this.selectedChartDate = newChartDate
      }
    },
    toggleShowChartOptions () {
      this.showChartOptions = !this.showChartOptions
    },
    toggleChartView () {
      if (this.chartViewStatus === 1) {
        this.chartViewStatus = 2
      } else {
        this.chartViewStatus = 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content-container {
  padding: 0 35px;
  /* height: 400px; */

  h2 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 400;
  }
  .main-title {

  }

  .main-section {
    margin: 30px 10px;
    background: #ffffffa0;
    padding: 5px;
    z-index: 20;
  }

  .main-section {position:-webkit-sticky; position:sticky; top:0;}

  .pointer {
    cursor: pointer;
  }

  .facility-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 10px;
    row-gap: 10px;
    margin-top: 20px;
  }

  meterreading-wrapper {
    float: right;
  }

  .meter-readings  th, td {
      padding: 10px;
      border-bottom: 1px solid #ddd;
  }

  .facility-header {
    background: #7FE87F;
    padding: 10px 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 1px 0 #ccc;
    z-index: 0;
  }

  .facility-header-suspect {
    background: #FBC742;
  }

  .facility-header-sub-suspect {
    //background: #FBC742;
    background: rgb(251,199,66);
    background: v-bind(facilityGradient);
  }

  .facility-header-inactive {
    background: #F2F2F7;
  }

  .facility-title {
    font-weight: 800;
    margin-right: 10px;
  }

 .facility-name {
    font-weight: 600;
    margin-left: 10px;
    margin-right: 10px;
  }

  .facility-time {
    margin-top: 6px;
    font-size: 80%;
  }

  .facility-detail-wrapper {
      float:right;
  }
  .facility-details {
    position: absolute;
    top: -10px;
    right: 50px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 20px;
    margin-left: 20px;
    z-index: 1;
  }
  .location-image {
    width: 20px;
    height: 20px;
    text-align: center;
    position: relative;
    z-index: 1;
  }

  .location-wrapper {
      float:right;
      width: 25px;
      height: 25px;
      z-index: 0;
  }

  .facility-image {
      width: 96px;
      height: 96px;
      margin-right: 10px;
      margin-left: 10px;
  }

  .alert-reason-wrapper {
      float:right;
      width: 25px;
      height: 25px;
      z-index: 0;
    }

  .alert-reason-number {
    text-align: center;
    position: relative;
    left: -10px;
    top: -15px;
    width: 15px;
    height: 15px;
    font-weight: 600;
    font-size: 80%;
    color: white;
    background: red;
    z-index: 2;
    border-radius: 20px;
    border-width: 1px;
  }

  .alert-reason-icon {
     text-align: center;
     position: relative;
     z-index: 1;
  }

  .facility-reason-number-inactive {
    display: none;
  }

  .device-reason-number-inactive {
      display: none;
  }

  .device-wrapper {
     display: grid;
     grid-template-columns: repeat(1, 1fr);
     column-gap: 10px;
     row-gap: 10px;
  }

  .device-item {
    overflow: hidden;
    background: #7FE87F;
    padding: 10px 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 1px 0 #ccc;
    margin-top: 20px;

    .device-headline {
      ;
    }

    .device-name {
      font-weight: 600;
      margin-left: 10px;
      margin-right: 10px;
    }

    .device-time {
      margin-top: 6px;
      font-size: 80%;
    }
  }

  .device-item-suspect {
    background: #FBC742;
  }

  .device-item-sub-suspect {
    //background: #FBC742;
    background: rgb(251,199,66);
    background: v-bind(deviceGradient);
  }

  .device-item-inactive {
    background: #F2F2F7;
  }

  .collections-wrapper {
    margin-top: 20px;
  }

  .sensor-wrapper {
     display: grid;
     grid-template-columns: repeat(2, 1fr);
     column-gap: 10px;
     row-gap: 10px;
  }

  .sensor-item-headline {
    overflow: hidden;
    background: #7FE87F;
    padding: 10px 10px;
    border-radius: 10px 10px 0 0;
    margin: 0;
    /* box-shadow: 0 1px 0 #ccc; */
  }

  .sensor-name {
    font-weight: 600;
    margin-left: 10px;
    margin-right: 10px;
  }

  sensor-time {
    margin-top: 6px;
  }

  .sensor-item-suspect {
    background: #FBC742;
  }

  .sensor-item-inactive {
    background: #F2F2F7;
  }

  .sensor-item-content {
    overflow: hidden;
    background: #white;
    padding: 10px 10px;
    border: 1px solid #808080;
    border-top: none;
    border-radius: 0 0 10px 10px;
    margin: 0;
    /* box-shadow: 0 1px 0 #ccc; */
  }

  .sensor-item-value {
    text-align: center;
    font-weight: 600;
    font-size: 123%;
  }

  .sensor-item-unit {
    font-size: 80%;
  }

  .sensor-item-system {
    text-align: center;
    font-size: 80%;
  }

  .sensor-item-time {
    text-align: center;
    font-size: 80%;
  }

  .sensor-value-content {
    overflow: hidden;
    background: #white;
    padding: 10px 10px;
    border: 1px solid #808080;
    border-radius: 10px 10px 10px 10px;
    margin: 0;
    /* box-shadow: 0 1px 0 #ccc; */
  }

.sensor-value-content-not-hidden {
    overflow: visible;
    background: #white;
    padding: 10px 10px;
    border: 1px solid #808080;
    border-radius: 10px 10px 10px 10px;
    margin: 0;
    /* box-shadow: 0 1px 0 #ccc; */
  }
  .graph-content {
    grid-column: span 2;
    min-height: 100px;
  }

  .meter-reading {
    margin-top: 20px;
  }

  .centeredText {
    margin: auto;
    padding: 10px;
  }

 .spacer {
    width: 100px;
  }

  #reloadContainer {
    width: 32px;
    height: 32px;
    position: relative;
  }
  #reloadText,
  #infoReload {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  #reloadText {
    font-size: 10px;
    margin: 8px;
    font-weight: 400;
  }
  #infoReload {
    z-index: 10;
  }
  #smallRefreshText {
      font-size: 9px;
      color: #929292;
      margin-bottom: 15px;
      font-weight: 400;
    }
}
</style>
