<template>
  <div class="container public">
    <div class="justify-content-center">
      <logo/>
      <div v-if="valid == true" class="row justify-content-center">

        <div class="form">
                  <div v-if="userinfo && userinfo.username" class="text-block">
                    <p>{{ $t('passwordPage.salutation', {username:  userinfo.username}) }}</p>
                    <p>{{ $t('passwordPage.subline', {email: userinfo.email}) }}</p>
                  </div>
          <form @submit.prevent="submitForm">
            <div class="form-group form-group-password">
              <label for="password1"> {{ $t('passwordPage.password1.label') }} </label>
              <password-control type="password" :class="{ 'is-invalid': $v.password1.$error }" id="password1" v-model="password1" />
            </div>
            <div class="invalid-password">
              <div v-if="$v.password1.$dirty" class="invalid-feedback password-feedback">
                <div v-if="$v.password1.$error">{{ $t('passwordPage.password1.required') }}</div>
              </div>
            </div>
            <div class="form-group form-group-password">
              <label for="password2"> {{ $t('passwordPage.password2.label') }} </label>
              <password-control type="password" :class="{ 'is-invalid': $v.password2.$error }" id="password2" v-model="password2" />
            </div>
            <div class="invalid-password">
              <div v-if="$v.password2.$dirty" class="invalid-feedback password-feedback">
                <div v-if="!$v.password2.required"> {{ $t('passwordPage.password2.required') }} </div>
                <div v-if="!$v.password2.sameAs"> {{ $t('passwordPage.password2.passwordsDontMatch') }} </div>
                <div v-if="!$v.password2.minLength"> {{ $t('passwordPage.password2.required') }} </div>
                <div v-if="!$v.password2.maxLength"> {{ $t('passwordPage.password2.required') }} </div>
              </div>
            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary btn-block">{{ $t('passwordPage.submit') }}</button>
            </div>
          </form>
        </div>
      </div>
    <div>
      <error-row @clearError="clearError" :errorMessage="errorMessage" />
    </div>
    <div class="row justify-content-center">
      <div class="form cancel">
        <button id="cancel" @click="cancel()" class="btn btn-secondary btn-block">{{ $t('passwordPage.cancel') }}</button>
      </div>
    </div>
    <page-footer/>
  </div>
</template>

<script>
import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'
import clientService from '@/services/client'
import Logo from '@/components/Logo.vue'
import PageViewMixin from '@/mixins/PageViewMixin.js'
import PasswordControl from '@/components/PasswordControl.vue'
import ErrorRow from '@/components/ErrorRow.vue'
import PageFooter from '@/components/PageFooter.vue'

export default {
  name: 'PasswordPage',
  mixins: [PageViewMixin],
  data: function () {
    return {
      token: '',
      valid: false,
      userinfo: {},
      password1: '',
      password2: '',
      errorMessage: ''
    }
  },
  components: {
    logo: Logo,
    'password-control': PasswordControl,
    'error-row': ErrorRow,
    'page-footer': PageFooter
  },
  validations: {
    password1: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(64)
    },
    password2: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(64),
      sameAs: sameAs('password1')
    }
  },
  created () {
    this.extractTokenFromUrl()
    if (this.token && this.token.length > 0) {
      this.validateToken(this.token)
    }
  },
  methods: {
    extractTokenFromUrl () {
      const token = this.$route.params.token
      if (token) {
        this.token = token
      }
    },
    validateToken (token) {
      const payload = { token: token }
      clientService.validateToken(payload).then(data => {
        this.userinfo = data.minimaluserinfo
        this.valid = true
      }).catch(error => {
        this.errorMessage = this.localizedBackendMessage(error.message)
      })
    },
    submitForm () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      const payload = { token: this.token, password: this.password1 }
      clientService.updatePassword(payload).then(data => {
        this.login()
      }).catch(error => {
        this.errorMessage = this.localizedBackendMessage(error.message)
      })
    },
    clearError () {
      this.errorMessage = ''
    },
    cancel () {
      this.valid = false
      this.$router.push({ name: 'login' })
    },
    login () {
      this.valid = false
      this.$router.push({ name: 'login', params: { organization: this.userinfo.organization, username: this.userinfo.username, password: this.password1 } })
    }
  }
}
</script>

<style lang="scss" scoped>

  .headline {
    font-family: 'Roboto Slab', Arial, Helvetica, sans-serif;
    font-size: 24px;
    font-weight: 800;
    font-style: normal;
    font-variant: normal;
    line-height: 130%;
    color: rgba(0,0,0,1);
    margin: 24px 0px 0px 0px;
  }

  .text-block-wrapper {
    justify-content: center;
    width: 90%
  }

  .text-block {
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: start;
  }

  .cancel {
    margin-top: 60px;
  }

  .btn-tgh {
    border-radius: 0;
    font-size: 0.8rem;
  }

  .form-group-password {
     margin-bottom: 0;
  }

  .invalid-password {
    margin-bottom: 1rem;
  }

  .password-feedback {
    display: block;
  }

</style>
