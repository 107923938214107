<template>
  <div class="container public">
    <div class="row justify-content-center">
      <div class="form">
        <logo/>
        <form @submit.prevent="submitForm">
          <error-row @clearError="clearError" :errorMessage="errorMessage" />
          <div class="form-group" v-if="showOrganizationPopup">
            <label for="organization">{{ $t('loginPage.form.organization.label') }}</label>
            <select class="form-control" id="organization" v-model="form.organization" >
              <option v-for="org in organizations" :value="org.code" :key="org.code">
                {{ org.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="username">{{ $t('loginPage.form.username.label') }}</label>
            <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.username.$dirty && $v.form.username.$error }" id="username" v-model.trim="form.username">
            <div class="invalid-feedback" v-if="$v.form.username.$dirty">
              <div v-if="!$v.form.username.required">{{ $t('loginPage.form.username.required') }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="password">{{ $t('loginPage.form.password.label') }}</label>
            <input type="password" class="form-control" :class="{ 'is-invalid': $v.form.username.$dirty && $v.form.password.$error }" id="password" v-model="form.password">
            <div class="invalid-feedback" v-if="$v.form.password.$dirty">
              <div v-if="!$v.form.password.required">{{ $t('loginPage.form.password.required') }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="org-code">{{ $t('loginPage.form.org-code.label') }}</label>
            <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.organization.$dirty && $v.form.organization.$error }" id="org-code" v-model.trim="form.organization">
            <div class="invalid-feedback" v-if="$v.form.organization.$dirty">
              <div v-if="!$v.form.organization.required">{{ $t('loginPage.form.organization.required') }}</div>
            </div>
          </div>
          <button type="submit" class="btn btn-primary btn-block">{{ $t('loginPage.form.submit') }}</button>
        </form>
        <div class="forgotten">
          <div class="form-group">
            <button id="forgotten" @click="passwordForgotten" class="btn btn-secondary btn-block">{{ $t('loginPage.form.forgotten.button') }}</button>
          </div>
        </div>
        <div>
          <form @submit.prevent="submitDemoForm">
            <br>
            <hr>
          <button type="submit" class="btn btn-primary btn-block">{{ $t('loginPage.form.submitDemo') }}</button>
          </form>
        </div>
      </div>
    </div>
    <page-footer/>
    <password-forgotten v-if="showForgottenModal" :company="company" :suggestedUserName="form.username" @close="closeForgottenModal" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import authenticationService from '@/services/authentication'
import { mapGetters } from 'vuex'
import Logo from '@/components/Logo.vue'
import ErrorRow from '@/components/ErrorRow.vue'
import PageFooter from '@/components/PageFooter.vue'
import PasswordForgottenModal from '@/modals/PasswordForgottenModal.vue'

export default {
  name: 'LoginPage',
  data: function () {
    return {
      form: {
        organization: '',
        username: '',
        password: ''
      },
      showOrganizationPopup: false,
      showForgottenModal: false,
      errorMessage: ''
    }
  },
  components: {
    logo: Logo,
    'password-forgotten': PasswordForgottenModal,
    'error-row': ErrorRow,
    'page-footer': PageFooter
  },
  validations: {
    form: {
      organization: {
        required
      },
      username: {
        required
      },
      password: {
        required
      }
    }
  },
  mounted () {
    var params = this.$route.params
    if (params) {
      if (params.organization) {
        this.form.organization = params.organization.code
      }
      if (params.username) {
        this.form.username = params.username
      }
      if (params.password) {
        this.form.password = params.password
      }
    }
  },
  computed: {
    ...mapGetters([
      'company'
    ]),
    organizations () {
      if (this.showOrganizationPopup) {
        return [{ code: 'CHL', name: 'Clever Home Labs GmbH' }, { code: 'MF', name: 'Matthias Fricke' }, { code: 'TB', name: 'Thomas Bauche' }]
      }
      return []
    }
  },
  methods: {
    submitDemoForm () {
      var params = { organization: 'DEMO', username: 'merick', password: 'geheim' }
      authenticationService.authenticate(params).then(() => {
        this.$router.push({ name: 'home' })
      }).catch((error) => {
        let message = error.message
        if (message === 'Invalid credentials') {
          message = this.$t('loginPage.server.invalidCredentials')
        } else if (message === 'Authentication failure') {
          message = this.$t('loginPage.server.authenticationFailure')
        }
        this.errorMessage = message
      })
    },
    submitForm () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      authenticationService.authenticate(this.form).then(() => {
        this.$router.push({ name: 'home' })
      }).catch((error) => {
        let message = error.message
        if (message === 'Invalid credentials') {
          message = this.$t('loginPage.server.invalidCredentials')
        } else if (message === 'Authentication failure') {
          message = this.$t('loginPage.server.authenticationFailure')
        }
        this.errorMessage = message
      })
    },
    passwordForgotten () {
      this.showForgottenModal = true
    },
    closeForgottenModal () {
      this.showForgottenModal = false
    },
    clearError () {
      this.errorMessage = ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .links {
    margin: 30px 0 50px 0;
    text-align: center;
  }

  .forgotten {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .btn-grey-border {
    color: darkgrey;
    background-color: white;
    border-color: darkgrey;
    border-radius: 0;
    font-size: 0.8rem;
    padding: .375rem .2rem;
    width: 11rem;
  }

</style>
