import Vue from 'vue'
import Router from 'vue-router'
import HomePage from '@/views/HomePage'
import LoginPage from '@/views/LoginPage'
import PasswordPage from '@/views/PasswordPage'
import OrganizationPage from '@/views/OrganizationPage'
import FacilitiesPage from '@/views/FacilitiesPage'
import CDCDevicesPage from '@/views/CDCDevicesPage'
import CDCSensorsPage from '@/views/CDCSensorsPage'
import SensorEventsPage from '@/views/SensorEventsPage'
import CDCDataValuesPage from '@/views/CDCDataValuesPage'
import UserPage from '@/views/UserPage'
import CollectionAddressesPage from '@/views/CollectionAddressesPage'
import UserPushTokensPage from '@/views/UserPushTokensPage'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomePage
    }, {
      path: '/login',
      name: 'login',
      component: LoginPage
    }, {
      path: '/changepassword/:token',
      name: 'changepassword',
      component: PasswordPage
    }, {
      path: '/organizations',
      name: 'organizations',
      component: OrganizationPage
    }, {
      path: '/facilities/:organizationid',
      name: 'facilities',
      component: FacilitiesPage
    }, {
      path: '/cdcdevices/:facilityid',
      name: 'cdcdevices',
      component: CDCDevicesPage
    }, {
      path: '/cdcsensors/:cdcdeviceid',
      name: 'cdcsensors',
      component: CDCSensorsPage
    }, {
      path: '/sensorevents/:cdcsensorid',
      name: 'sensorevents',
      component: SensorEventsPage
    }, {
      path: '/cdcdatavalues/:cdcdeviceid/:from/:until',
      name: 'cdcdatavalues',
      component: CDCDataValuesPage
    }, {
      path: '/users',
      name: 'users',
      component: UserPage
    }, {
      path: '/userpushtokens/:userid',
      name: 'userpushtokens',
      component: UserPushTokensPage
    }, {
      path: '/collections',
      name: 'collections',
      component: CollectionAddressesPage
    }
  ]
})
