export const client = state => {
  return state.client
}

export const errorflag = state => {
  return state.errorflag
}

export const errorinfo = state => {
  return state.errorinfo
}

export const running = state => {
  return state.running
}

export const hasUsers = state => {
  return state.users.length > 0
}

export const users = state => {
  return state.users
}

export const userMap = state => {
  const map = {}
  state.users.forEach(user => {
    map[user.id] = user
  })
  return map
}

export const hasOrganizations = state => {
  return state.organizations.length > 0
}

export const organizations = state => {
  return state.organizations
}

export const organizationMap = state => {
  const map = {}
  state.organizations.forEach(organization => {
    map[organization.id] = organization
  })
  return map
}

export const facilities = state => {
  return state.facilities
}

export const hasFacilities = state => {
  return state.facilities.length > 0
}

export const facilityMap = state => {
  const map = {}
  state.facilities.forEach(facility => {
    map[facility.id] = facility
  })
  return map
}

export const cdcdevices = state => {
  return state.cdcdevices
}

export const hasCdcdevices = state => {
  return state.cdcdevices.length > 0
}

export const cdcDeviceMap = state => {
  const map = {}
  state.cdcdevices.forEach(cdcdevice => {
    map[cdcdevice.id] = cdcdevice
  })
  return map
}

export const cdcsensors = state => {
  return state.cdcsensors
}

export const hasCdcsensors = state => {
  return state.cdcsensors.length > 0
}

export const cdcSensorMap = state => {
  const map = {}
  state.cdcsensors.forEach(cdcsensor => {
    map[cdcsensor.id] = cdcsensor
  })
  return map
}

export const sensorevents = state => {
  return state.sensorevents
}

export const hasSensorevents = state => {
  return state.sensorevents.length > 0
}

export const cdcdatavalues = state => {
  return state.cdcdatavalues
}

export const hasCdcdatavalues = state => {
  return state.cdcdatavalues.length > 0
}

export const hasRoles = state => {
  return state.roles.length > 0
}

export const roles = state => {
  return state.roles
}

export const roleMap = state => {
  const map = {}
  state.roles.forEach(role => {
    map[role.id] = role
  })
  return map
}

export const userpushtokens = state => {
  return state.userpushtokens
}

export const hasUserPushTokens = state => {
  return state.userpushtokens.length > 0
}

export const collections = state => {
  return state.collections
}

export const hasCollections = state => {
  return state.collections.length > 0
}

export const company = state => {
  return state.company
}

export const dailyTotals = state => {
  return state.dailyTotals
}

export const sensorAggregations = state => {
  return state.sensorAggregations
}

export const hasSensorAggregations = state => {
  if (state.sensorAggregations.aggregations) {
    return state.sensorAggregations.aggregations.length > 0
  }
  return false
}
